export function useStorage(mode: 'local' | 'session') {
  try {
    const storage = mode === 'local' ? window.localStorage : window.sessionStorage;
    return storage;
  } catch (e) {
    // Warning, this will get erased on refresh. It means cookies are blocked.
    const storageProps = ['getItem','setItem','clear','length', 'removeItem'];
    const getKeys = (storage: Storage) => Object.keys(storage).filter(key => storageProps.indexOf(key) === -1);
    const storage = new Proxy({} as Storage, {
      get(target, prop) {
        switch (prop) {
          case 'length':
            return getKeys(target).length;
          case 'clear':
            return () => { for (const key in getKeys(target)) { delete target[key] } };
          case 'getItem':
            return (key: string) => target[key];
          case 'setItem':
            return (key: string, value: string) => { 
              if (storageProps.includes(key)) 
                throw new Error("Cannot set named prop"); 
              return target[key] = value 
            };
          case 'removeItem':
            return (key: string) => { delete target[key] };
        }
      } 
    });
    return storage;
  }
}