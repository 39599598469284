import { BooleanExpr, GenericTemplatedBlock, SummaryExpr, ValueExpr } from "../survey";

export function CompileExpressionToJS(cond: BooleanExpr | ValueExpr | GenericTemplatedBlock<BooleanExpr[]>, 
  context?: { currentUser?: string }
) {
  function generateConditions(expr: BooleanExpr | ValueExpr | GenericTemplatedBlock<BooleanExpr[]> | SummaryExpr): string {
    const dateKinds = ['Last Modified Date', 'As Date', 'Shift Date', 'Now'];
    switch (expr.kind) {
      case 'And':
        return '(' + expr.clauses.map(generateConditions).join(' &&\n ') + ')';
      case 'Or':
        return '(' + expr.clauses.map(generateConditions).join(' ||\n ') + ')';
      case 'Not': 
        return '(! ' + generateConditions(expr.clause) + ')';
      case 'Equals':
        return `info.${expr.field} === '${expr.value}'`;
      case 'Not Equal':
        return `(info.${expr.field} !== '${expr.value}')`;
      case 'Exists':
        return `(!!info.${expr.field})`;
      case 'DoesntExist':
        return `(!info.${expr.field})`;
      case 'Never':
        return 'false';
      case 'Code Boolean Expr':
        return '(' + expr.code + ')';
        // TOOD: Thread edit times into formulas
      case 'After':
      case 'Before':
        const { year, month, day } = expr.date;
        const { hour, minute } = expr.time;
        const compare = Date.UTC(
          year,
          Math.max(month - 1, 0),
          day,
          Math.max(hour - 1, 0),
          Math.max(minute - 1, 0)
        );

        return `Date.now() ${expr.kind === 'After' ? '>' : '<'} ${compare}`;
      case 'Last Modified':
        return 'false'
      case 'Last Modified Date':
        return `info.${expr.field.replace('.value', '.created_at')}`;
      case 'Field':
        return `info.${expr.field}`;
      case 'Value Contains':
        let toLowerCase = expr.caseInsensitive ? '.toLowerCase()' : '';
        if (expr.mode === 'comma separated list')
          return `((${generateConditions(expr.value)} || '')${toLowerCase}.split(',').includes(${generateConditions(expr.contains)}${toLowerCase}))`;
        return `((${generateConditions(expr.value)} || '')${toLowerCase}.indexOf(${generateConditions(expr.contains)}${toLowerCase}) !== -1)`;
      case 'Value Equals':
        return `(${generateConditions(expr.value)} === ${generateConditions(expr.equals)})`;
      case 'Value Not Equal':
        return `(${generateConditions(expr.value)} !== ${generateConditions(expr.notEqual)})`;
      case 'Value Empty':
        return `(!${generateConditions(expr.value)})`;
      case 'Value Not Empty':
        return `(!!${generateConditions(expr.value)})`;
      case 'Value Less Than':
        if (dateKinds.includes(expr.value.kind)) {
          return `(${generateConditions(expr.value)} < ${generateConditions(expr.lessThan)})`;
        }
        return `(parseFloat(${generateConditions(expr.value)}) < parseFloat(${generateConditions(expr.lessThan)}))`;
      case 'Value Greater Than':
        if (dateKinds.includes(expr.value.kind)) {
          return `(${generateConditions(expr.value)} > ${generateConditions(expr.greaterThan)})`;
        }
        return `(parseFloat(${generateConditions(expr.value)}) > parseFloat(${generateConditions(expr.greaterThan)}))`;
      case 'When':
        return `(() => {${(expr.when || [{
          cond: { kind: 'Never' },
          then: expr.otherwise
        }]).map((e, i) => 
          (i == 0 ? '' : 'else ') + `if (${generateConditions(e.cond)}) { return ${generateConditions(e.then)}; }`).join('\n')
        } else { return ${generateConditions(expr.otherwise)} } })()`;
      case 'Transform String':
        let s = generateConditions(expr.value);
        if (expr.transform === 'lowercase') {
          return `${s}.toLowerCase()`;
        } else if (expr.transform === 'uppercase') {
          return `${s}.toUpperCase()`;
        } else if (expr.transform === 'titlecase') {
          return `${s}.split(' ').map((word) => word[0].toUpperCase() + word.slice(1)).join(' ')`;
        } else if (expr.transform === 'trim') {
          return `${s}.trim()`;
        }
        return s;
      case 'StringValue':
        return `'${expr.value.replace("'", "\\'")}'`;
      case 'NumericValue':
      case 'BooleanValue':
        return `${expr.value}`;
      case 'Current User':
        return `'${context?.currentUser?.replace("'", "\\'") || 'No user set'}'`;
      case 'Applicant Luck':
        // TODO: thread luck into formulas
        return 'Luck not available in computations';
      case 'As Date':
        return `new Date(${generateConditions(expr.value)})`;
      case 'Shift Date':
        return `((date) => {
                    date.setFullYear(date.getFullYear() + ${expr.interval.years || 0});
                    date.setMonth(date.getMonth() + ${expr.interval.months || 0});
                    date.setDate(date.getDate() + ${expr.interval.days || 0});
                    date.setHours(date.getHours() + ${expr.interval.hours || 0});
                    date.setMinutes(date.getMinutes() + ${expr.interval.minutes || 0});
                    return date;
                })(${generateConditions(expr.value)})`;
      case 'Now':
        return 'new Date()';
      case 'Templated Block':
        throw "expressed has not been expanded";
      case 'Count':
        return ``;
      case 'Mean':
        return ``;
      case 'Median':
        return ``;
      case 'Sum':
        return ``;
      case 'Round':
        return `( Number(${generateConditions(expr.value)}).toFixed(${expr.places || 0}) )`
      case 'Add':
        if (Array.isArray(expr.secondValue)) {
          return `( parseFloat(${generateConditions(expr.baseValue)}) + ` + expr.secondValue.map(value => `parseFloat(${generateConditions(value)})`).join(' +\n ') + ')';
        } else {
          return `( parseFloat(${generateConditions(expr.baseValue)}) + parseFloat(${generateConditions(expr.secondValue)}) )`;
        }
      case 'Subtract':
        if (Array.isArray(expr.secondValue)) {
          return `( parseFloat(${generateConditions(expr.baseValue)}) - ` + expr.secondValue.map(value => `parseFloat(${generateConditions(value)})`).join(' -\n ') + ')';
        } else {
          return `( parseFloat(${generateConditions(expr.baseValue)}) - parseFloat(${generateConditions(expr.secondValue)}) )`;
        }
      case 'Multiply':
        if (Array.isArray(expr.secondValue)) {
          return `( parseFloat(${generateConditions(expr.baseValue)}) * ` + expr.secondValue.map(value => `parseFloat(${generateConditions(value)})`).join(' *\n ') + ')';
        } else {
          return `( parseFloat(${generateConditions(expr.baseValue)}) * parseFloat(${generateConditions(expr.secondValue)}) )`;
        }
      case 'Divide':
        if (Array.isArray(expr.secondValue)) {
          return `( parseFloat(${generateConditions(expr.baseValue)}) / ` + expr.secondValue.map(value => `parseFloat(${generateConditions(value)})`).join(' /\n ') + ')';
        } else {
          return `( parseFloat(${generateConditions(expr.baseValue)}) / parseFloat(${generateConditions(expr.secondValue)}) )`;
        }                  
      case 'Bucket Date':
        return `((date) => {
                        if ('${expr.bucket}' === 'week') date.setDate(date.getDate() - date.getDay() + 1);
                        switch ('${expr.bucket}') {
                            case 'year':
                                date.setMonth(0);
                            case 'month':
                                date.setDate(1);
                            case 'week':
                            case 'day':
                                date.setHours(0);
                            case 'hour':
                                date.setMinutes(0);
                            case 'minute':
                                date.setSeconds(0);
                                date.setMilliseconds(0);
                                break;
                        }
                        return date;
                    })(${generateConditions(expr.value)})`;
      case 'Sync Status':
        return `"${expr.kind}"`;
      case 'Expand':
        return `JSON.parse(${expr.value})`;
      case 'Concat':
        return expr.value.map(generateConditions).join(' + "' + (expr.delimiter === '' || expr.delimiter ? expr.delimiter : ' ') + '" +');
      case 'Extract':
        const projection = (Array.isArray(expr.path) ? expr.path : [expr.path]).join('?.');
        return `JSON.parse(${typeof expr.value === 'string' ? ('info.' + expr.value) : generateConditions(expr.value)})?.${projection}`
      default:
        let _: never = expr;
    }
    return '';
  }

  return generateConditions(cond);
}
