import { BooleanExpr, GenericTemplatedBlock, SummaryExpr, ValueExpr } from "../survey";

export function CompileExpressionToEnglish(cond: BooleanExpr | ValueExpr | GenericTemplatedBlock<BooleanExpr[]>,
  context?: { currentUser?: string }
) {
  function spaces(level: number) {
    return '  '.repeat(level);
  }

  function generateConditions(expr: BooleanExpr | ValueExpr | GenericTemplatedBlock<BooleanExpr[]> | SummaryExpr, level: number): string {
    const dateKinds = ['Last Modified Date', 'As Date', 'Shift Date', 'Now'];
    switch (expr.kind) {
      case 'And':
        return '(' + expr.clauses.map(clause => generateConditions(clause, level + 1)).join(`\n${spaces(level)}AND `) + ')';
      case 'Or':
        return '(' + expr.clauses.map(clause => generateConditions(clause, level + 1)).join(`\n${spaces(level)}OR `) + ')';
      case 'Not':
        return '(NOT ' + generateConditions(expr.clause, level + 1) + ')';
      case 'Equals':
        return `${expr.field} === '${expr.value}'`;
      case 'Not Equal':
        return `(${expr.field} !== '${expr.value}')`;
      case 'Exists':
        return `EXISTS(${expr.field})`;
      case 'DoesntExist':
        return `DOES_NOT_EXIST(${expr.field})`;
      case 'Never':
        return 'false';
      case 'Code Boolean Expr':
        return '(' + expr.code.trim() + ')';
      case 'After':
      case 'Before':
        const { year, month, day } = expr.date;
        const { hour, minute } = expr.time;
        const compare = new Date(Date.UTC(
          year,
          Math.max(month - 1, 0),
          day,
          Math.max(hour - 1, 0),
          Math.max(minute - 1, 0)
        ));

        return `NOW ${expr.kind === 'After' ? '>' : '<' } ${compare.toLocaleString()}`;
      case 'Last Modified':
        return 'false'
      case 'Last Modified Date':
        return `${expr.field.replace('.value', '.created_at')}`;
      case 'Field':
        return `${expr.field}`;
      case 'Value Contains':
        let toLowerCase = expr.caseInsensitive ? 'LOWERCASE' : '';
        if (expr.mode === 'comma separated list')
          return `((${toLowerCase}(${generateConditions(expr.value, level + 1)} OR '')).SPLIT(',').INCLUDES(${toLowerCase}(${generateConditions(expr.contains, level + 1)})))`;
        return `((${toLowerCase}(${generateConditions(expr.value, level + 1)} || '')).INCLUDES(${toLowerCase}(${generateConditions(expr.contains, level + 1)})))`;
      case 'Value Equals':
        return `(${generateConditions(expr.value, level + 1)} === ${generateConditions(expr.equals, level + 1)})`;
      case 'Value Not Equal':
        return `(${generateConditions(expr.value, level + 1)} !== ${generateConditions(expr.notEqual, level + 1)})`;
      case 'Value Empty':
        return `VALUE_EMPTY(${generateConditions(expr.value, level + 1)})`;
      case 'Value Not Empty':
        return `VALUE_NOT_EMPTY(${generateConditions(expr.value, level + 1)})`;
      case 'Value Less Than':
        if (dateKinds.includes(expr.value.kind)) {
          return `(${generateConditions(expr.value, level + 1)} < ${generateConditions(expr.lessThan, level + 1)})`;
        }
        return `((${generateConditions(expr.value, level + 1)}) < (${generateConditions(expr.lessThan, level + 1)}))`;
      case 'Value Greater Than':
        if (dateKinds.includes(expr.value.kind)) {
          return `(${generateConditions(expr.value, level + 1)} > ${generateConditions(expr.greaterThan, level + 1)})`;
        }
        return `((${generateConditions(expr.value, level + 1)}) > (${generateConditions(expr.greaterThan, level + 1)}))`;
      case 'When':
        return `${(expr.when || [{
          cond: { kind: 'Never' },
          then: expr.otherwise
        }]).map((e, i) =>
          (i == 0 ? '' : 'ELSE ') + `IF (${generateConditions(e.cond, level + 1)}) THEN ${generateConditions(e.then, level + 1)}`).join(`\n${spaces(level)}`)
        } ELSE ${generateConditions(expr.otherwise, level + 1)}`;
      case 'Transform String':
        let s = generateConditions(expr.value, level + 1);
        if (expr.transform === 'lowercase') {
          return `LOWERCASE(${s})`;
        } else if (expr.transform === 'uppercase') {
          return `UPPERCASE(${s})`;
        } else if (expr.transform === 'titlecase') {
          return `TITLECASE(${s})`;
        } else if (expr.transform === 'trim') {
          return `TRIM(${s})`;
        }
        return s;
      case 'StringValue':
        return `${expr.value.replace("'", "\\'")}`;
      case 'NumericValue':
      case 'BooleanValue':
        return `${expr.value}`;
      case 'Current User':
        return `'${context?.currentUser?.replace("'", "\\'") || 'No user set'}'`;
      case 'Applicant Luck':
        // TODO: thread luck into formulas
        return 'Luck not available in computations';
      case 'As Date':
        const dateStr = generateConditions(expr.value, level + 1);
        return `DATE(${new Date(dateStr).toLocaleString()})`;
      case 'Shift Date':
        let shifts = `${expr.interval.years ? expr.interval.years + ' YEARS AND ' : ''}`
                    + `${expr.interval.months ? expr.interval.months + ' MONTHS AND ' : ''}`
                    + `${expr.interval.days ? expr.interval.days + ' DAYS AND ' : ''}`
                    + `${expr.interval.hours ? expr.interval.hours + ' HOURS AND ' : ''}`
                    + `${expr.interval.minutes ? expr.interval.minutes + ' MINUTES' : ''}`;
        if (shifts.endsWith(' AND ')) {
          shifts = shifts.slice(0, -5);
        }
        return `SHIFT_DATE_BY(${shifts}, (${generateConditions(expr.value, level + 1)}))`;
      case 'Now':
        return 'NOW()';
      case 'Templated Block':
        throw "expressed has not been expanded";
      case 'Count':
        return ``;
      case 'Mean':
        return ``;
      case 'Median':
        return ``;
      case 'Sum':
        return ``;
      case 'Round':
        return `ROUND(${generateConditions(expr.value, level + 1)})`
      case 'Add':
        if (Array.isArray(expr.secondValue)) {
          return `(${generateConditions(expr.baseValue, level + 1)}` + expr.secondValue.map(value => `\n${spaces(level)}+ (${generateConditions(value, level + 1)})`).join('') + ')';
        } else {
          return `(${generateConditions(expr.baseValue, level + 1)} + (${generateConditions(expr.secondValue, level + 1)}))`;
        }
      case 'Subtract':
        if (Array.isArray(expr.secondValue)) {
          return `(${generateConditions(expr.baseValue, level + 1)}` + expr.secondValue.map(value => `\n${spaces(level)}- (${generateConditions(value, level + 1)})`).join('') + ')';
        } else {
          return `(${generateConditions(expr.baseValue, level + 1)} - (${generateConditions(expr.secondValue, level + 1)}))`;
        }
      case 'Multiply':
        if (Array.isArray(expr.secondValue)) {
          return `(${generateConditions(expr.baseValue, level + 1)}` + expr.secondValue.map(value => `\n${spaces(level)}* (${generateConditions(value, level + 1)})`).join('') + ')';
        } else {
          return `(${generateConditions(expr.baseValue, level + 1)} * (${generateConditions(expr.secondValue, level + 1)}))`;
        }
      case 'Divide':
        if (Array.isArray(expr.secondValue)) {
          return `(${generateConditions(expr.baseValue, level + 1)}` + expr.secondValue.map(value => `\n${spaces(level)}/ (${generateConditions(value, level + 1)})`).join('') + ')';
        } else {
          return `(${generateConditions(expr.baseValue, level + 1)} / (${generateConditions(expr.secondValue, level + 1)}))`;
        }
      case 'Bucket Date':
        return `BUCKET_DATE_BY_${expr.bucket.toUpperCase()}(${generateConditions(expr.value, level + 1)})`;
      case 'Sync Status':
        return `"${expr.kind}"`;
      case 'Expand':
        return `"${expr.value}[]"`
      case 'Concat':
        return expr.value.map(generateConditions).join(expr.delimiter);
      case 'Extract':
        return (typeof expr.value === 'string' ? expr.value : generateConditions(expr.value, level + 1)) + (Array.isArray(expr.path) ? expr.path.join('.') : '.' + expr.path); 
      default:
        let _: never = expr;
    }
    return '';
  }

  return generateConditions(cond, 0);
}
