"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScanBarcode = exports.getSQS = void 0;
var neverthrow_1 = require("neverthrow");
var client_sqs_1 = require("@aws-sdk/client-sqs");
var client_dynamodb_1 = require("@aws-sdk/client-dynamodb");
var uuid_1 = require("uuid");
function getSQS() {
    return new client_sqs_1.SQS(__assign({}, (process.env.NODE_ENV === 'development' ? {
        endpoint: 'http://localhost:9324'
    } : {})));
}
exports.getSQS = getSQS;
exports.ScanBarcode = {
    getSlugs: function (params) {
        var slugs = [
            "_barcode_results",
            "_barcode_results_error",
            "_barcode_queue_id"
        ];
        var dependencies = [];
        return (0, neverthrow_1.ok)({ slugs: slugs, dependencies: dependencies });
    },
    compute: function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var secrets, parentField, queued, updates, sqs, QueueUrl, queueId, command, command, dynamoResponse, e_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        // Add to the Queue to scan if it has not already been added
                        if (!params.targetField.endsWith("_barcode_results"))
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        return [4 /*yield*/, params.aidkitCrypto];
                    case 1:
                        secrets = _c.sent();
                        if (!secrets)
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        parentField = params.targetField.replace("_barcode_results", "");
                        if (!params.newInfoKeys[parentField])
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        queued = params.newInfoKeys[parentField + "_queue_id"] || params.newInfoKeys[parentField + "_barcode_queue_id"];
                        updates = {};
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 8, , 9]);
                        if (!!queued) return [3 /*break*/, 5];
                        sqs = getSQS();
                        QueueUrl = secrets.rbsQueueUrl;
                        if (!QueueUrl)
                            return [2 /*return*/, (0, neverthrow_1.err)("no_queue_url")];
                        queueId = (0, uuid_1.v4)();
                        command = new client_dynamodb_1.PutItemCommand({
                            TableName: params.deploymentKey + ".queue.scan_barcode",
                            Item: {
                                id: { S: queueId },
                                imageUrl: { S: params.newInfoKeys[parentField] },
                                status: { S: 'QUEUED' },
                            }
                        });
                        return [4 /*yield*/, params.dynamo.send(command)];
                    case 3:
                        _c.sent();
                        return [4 /*yield*/, sqs.sendMessage({
                                MessageBody: JSON.stringify({
                                    kind: 'Realtime',
                                    program: params.deploymentKey,
                                    id: queueId,
                                    action: 'Scan Barcode',
                                    imageUrl: params.newInfoKeys[parentField],
                                    timestamp: new Date().getTime(),
                                }),
                                QueueUrl: QueueUrl
                            })];
                    case 4:
                        _c.sent();
                        updates[parentField + "_barcode_queue_id"] = queueId;
                        return [3 /*break*/, 7];
                    case 5:
                        command = new client_dynamodb_1.GetItemCommand({
                            TableName: params.deploymentKey + ".queue.scan_barcode",
                            Key: {
                                "id": { S: queued }
                            }
                        });
                        return [4 /*yield*/, params.dynamo.send(command)];
                    case 6:
                        dynamoResponse = _c.sent();
                        if (!dynamoResponse.Item) {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_a = {}, _a[parentField + "_barcode_results_error"] = "ERROR: No queue item", _a))];
                        }
                        // Check if it has been processed
                        if (dynamoResponse.Item.finished && dynamoResponse.Item.result) {
                            // Get the results
                            updates[parentField + "_barcode_results"] = dynamoResponse.Item.result.S;
                        }
                        else if (dynamoResponse.Item.errored) {
                            updates[parentField + "_barcode_results_error"] = dynamoResponse.Item.error.S;
                        }
                        _c.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        e_1 = _c.sent();
                        if (e_1 instanceof client_dynamodb_1.ResourceNotFoundException) {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_b = {}, _b[parentField + "_barcode_results_error"] = "error_queue_not_found", _b))];
                        }
                        throw e_1;
                    case 9: return [2 /*return*/, (0, neverthrow_1.ok)(updates)];
                }
            });
        });
    }
};
