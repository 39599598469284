import { Modal, useToast } from "@aidkitorg/component-library";
import type { SurveyCompletionResult } from '@aidkitorg/robonav/lib';
import * as v0 from '@aidkitorg/types/lib/survey';
import { CollectScopeForUser, PermissionScope } from '@aidkitorg/types/lib/translation/permissions';
import { expandTemplates } from '@aidkitorg/types/lib/translation/v0_to_legacy';
import { limitConcurrentRequests } from '@aidkitorg/types/lib/util';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { usePost, useRoboNav } from './API';
import { Dropdown } from './Components/Dropdown';
import { UserInfoContext } from './Context';
import { stringToColor } from './Realtime';
import { BUTTON_CLASS, mapStrToList, SpacedSpinner, useMarkdown } from './Util';
import { mapSubsurveyPaths, PathNode, QuestionNodeType, SubsurveyNode } from './utils/mapSubsurveyPaths';

export const EXCLUDABLE_TYPES_ARRAY = ['Show Field', 'Computed', 'Validated'] as const;
export type ExcludableType = (typeof EXCLUDABLE_TYPES_ARRAY)[number];

function QuestionNode(props: { questionNode: QuestionNodeType, displayIndex: string, thisQuestionProgressNumbers: number, mostAnswers: number }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const percentAnswered = ((props.thisQuestionProgressNumbers ?? 0) / props.mostAnswers) * 100;
  return (
    <div className='flex justify-between'>
      <div className='w-full cursor-pointer text-black hover:underline' onClick={() => setIsModalOpen(true)}>
        <span style={{ background: stringToColor(props.questionNode.questionType), borderColor: stringToColor(props.questionNode.questionType, true) }} className='mr-1 rounded-xl px-1 py-[1px] border-[1px] text-xs'>{props.questionNode.questionType}</span>
        <span>{props.displayIndex} - </span>
        {props.questionNode.optional && <span className='bg-amber-100 mr-1 rounded-xl px-1 py-[1px] border-[1px] border-amber-200 text-xs'>Optional</span>}
        {props.questionNode.hidden && <span className='bg-amber-100 mr-1 rounded-xl px-1 py-[1px] border-[1px] border-amber-200 text-xs'>Hidden</span>}
        <span>{props.questionNode.targetField}</span>
      </div>
      <span className='pl-2' style={{ minWidth: '250px', width: '250px', backgroundImage: `linear-gradient(to right, lightblue 0%, lightblue ${percentAnswered}%, white ${percentAnswered}%, white 100%` }}>
        {props.thisQuestionProgressNumbers ?? 0}
      </span>
      <QuestionNodeModal
        questionType={props.questionNode.questionType}
        displayIndex={props.displayIndex}
        targetField={props.questionNode.targetField}
        choices={props.questionNode.choices}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        content={props.questionNode.content?.['en']}
      />
    </div>
  );
}

export function QuestionNodeModal(props: {
  questionType: string
  displayIndex: string,
  targetField: string,
  choices: QuestionNodeType['choices'],
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  content?: string,
}) {
  return <Modal
    title={`Question ${props.displayIndex} - ${props.targetField}`}
    open={props.isModalOpen}
    size='lg'
    onClose={(value: boolean) => props.setIsModalOpen(value)}
    children={
      <div>
        <table className='divide-y divide-gray-200 w-full'>
          <thead className='bg-gray-50'>
            <tr>
              <th scope='col' className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Type</th>
              <th scope='col' className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Content</th>
            </tr>
          </thead>
          <tbody>
            <tr className='bg-white hover:bg-gray-50 text-gray-700 hover:text-black'>
              <td className="px-6 py-4 text-sm">{props.questionType}</td>
              {props.content && (<td className="px-6 py-4 text-sm">{useMarkdown(props.content)}</td>)}
            </tr>
          </tbody>
        </table>
        {(props.choices && props.choices.length > 0) &&
          <>
            <h4>Question Choices</h4>
            <table className='divide-y divide-gray-200 w-full'>
              <thead className='bg-gray-50'>
                <tr>
                  <th scope='col' className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Value</th>
                  <th scope='col' className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Content</th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {props.choices.map((choice, i) => {
                  return <tr key={choice.value + i} className='bg-white hover:bg-gray-50 text-gray-700 hover:text-black'>
                    <td className="px-6 py-4 text-sm">{choice?.value}</td>
                    <td className="px-6 py-4 text-sm">{choice?.label?.en}</td>
                  </tr>
                })}
              </tbody>
            </table>
          </>}
      </div>
    }
  />
}

export function ConsensedSurveyView() {
  // TODO: survey should probably be moved from a URL param to a hash so that it can be changed without
  // causing the page to be refreshed
  const { survey = '', source = 'program' } = useParams<{ survey?: string, source: 'robonav' | 'program' }>();
  const loadSurvey = usePost('/survey/load_survey');
  const getProgress = usePost('/program/admin/get_progress');
  const listRuns = useRoboNav('/bot/list_survey_results');
  const getRunResult = useRoboNav('/bot/get_survey_result');
  const userInfo = useContext(UserInfoContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subsurveyPaths, setSubsurveyPaths] = useState<SubsurveyNode[] | null>(null);
  const [excludedTypes, setExcludedTypes] = useState<Record<ExcludableType, boolean>>({ 'Show Field': true, 'Computed': true, 'Validated': true });
  const [selectedSubsurvey, setSelectedSubsurvey] = useState<'all' | SubsurveyNode | null>(null);
  const [isLoadingNumbers, setIsLoadingNumbers] = useState<boolean>(false);
  const [surveyProgressNumbers, setSurveyProgressNumbers] = useState<Record<string, number>>({});
  const [uids, setUids] = useState<string>('');
  // This is used to compare progress across questions.
  const [mostAnswers, setMostAnswers] = useState<number>(1);
  const { toast } = useToast();

  function updateExcludedTypes(toggleExclusion: ExcludableType) {
    setExcludedTypes(prevState => {
      const newExcludedTypes = { ...prevState }
      newExcludedTypes[toggleExclusion] = !newExcludedTypes[toggleExclusion];
      return newExcludedTypes;
    });
  }

  useEffect(() => {
    if (subsurveyPaths !== null || !userInfo.uid) {
      return;
    }

    setIsLoading(true);
    (async () => {
      const everything = await loadSurvey({ name: 'entireprogram' });
      const expandedSurvey = expandTemplates(everything.config) as v0.ExpandedSurvey;
      let scope: PermissionScope | undefined;
      if ('users' in expandedSurvey) {
        scope = CollectScopeForUser(userInfo.uid!, expandedSurvey.survey, expandedSurvey.users);
      }
      setSubsurveyPaths(mapSubsurveyPaths(expandedSurvey, scope));
      setIsLoading(false);
    })();
  }, [userInfo]);

  useEffect(() => {
    if (subsurveyPaths && survey) {
      const prechosenSubSurvey = survey === 'all' ? 'all' : subsurveyPaths.find((sp: SubsurveyNode) => sp.name === survey)
      setSelectedSubsurvey(prechosenSubSurvey ?? null);
    }
  }, [subsurveyPaths]);

  function renderSubNodeOuter(nodes: PathNode[] | undefined, prevIndex: string = '') {

    function renderSubNode(subNode: PathNode, prevIndex: string = '', indexForKey: number) {
      if (subNode.type === 'Question') outerCount++;
      const displayIndex = (prevIndex ? prevIndex + '.' + outerCount : outerCount) + ''; // cast to string
      switch (subNode.type) {
        case 'Question':
          if (!excludedTypes[subNode.questionType as ExcludableType]) {
            return <QuestionNode
              key={subNode.targetField + displayIndex}
              questionNode={subNode}
              displayIndex={displayIndex}
              thisQuestionProgressNumbers={surveyProgressNumbers[subNode.targetField]}
              mostAnswers={mostAnswers}
            />;
          }
          return <></>;
        case 'Section':
          return <h4 key={subNode.name + displayIndex} className='mt-2'>
            {subNode.name}
          </h4>;
        case 'Condition':
          // It is possible to have multiple conditions with the same displayIndex and condition
          return <div key={subNode.condition + displayIndex + indexForKey} className='bg-blue-100 border-t-2 border-l-2 border-slate-300 border-dashed rounded-l-lg'>
            <div className='pl-1 flex justify-between'>{subNode.condition}</div>
            <div className='bg-teal-100 pl-4 relative'>
              {renderSubNodeOuter((subNode.subNodesTrue || []), displayIndex)}
            </div>
            {subNode.subNodesFalse !== undefined && <div className='bg-purple-100 pl-4 relative'>
              {renderSubNodeOuter((subNode.subNodesFalse || []), displayIndex)}
            </div>}
          </div>
        default:
          return <div>Unknown node type: {subNode.type}</div>;
      }
    }

    let outerCount = 0;
    return (nodes || []).map((subNode, i) => renderSubNode(subNode, prevIndex, i))
  }

  async function fetchSurveyProgressNumbers(targetFields: string[]) {
    let progressResults: { key: string, sum: string }[] | { error: string };
    if (source === 'robonav') {
      console.debug('loading progress from robonav');
      const runs = await Promise.all((subsurveyPaths || [])
        .map(async ({ name }) => {
          const { runKeys } = await listRuns({ surveyName: name })
          const runResults =
            (await limitConcurrentRequests(runKeys.map((key) => () => getRunResult({ key })), 5))
              .filter(r => r.status === 'fulfilled').map((r: any) => r.value as SurveyCompletionResult);

          return runResults
            .flatMap(r => Object.keys(r.persona ?? {}));
        }));

      const results = runs
        .flatMap(r => r)
        .reduce((sums, field) => sums.set(field, (sums.get(field) || 0) + 1), new Map<string, number>());

      progressResults = Array.from(results.entries()).map(([key, sum]) => ({ key, sum: sum.toString() }))

    } else {
      progressResults = await getProgress({ targetFields, uids: mapStrToList(uids) })
    }
    if ('error' in progressResults) {
      toast({
        description: progressResults.error,
        variant: 'error'
      });
    } else {
      const fieldDictionary: Record<string, number> = {};
      let mostAnswers = 1;
      progressResults.forEach(row => {
        const sum = Number(row.sum);
        if (sum > mostAnswers) {
          mostAnswers = sum;
        }
        fieldDictionary[row.key] = sum;
      });
      setMostAnswers(mostAnswers);
      setSurveyProgressNumbers(fieldDictionary);
      setIsLoadingNumbers(false);
    }
  }

  function selectSubsurvey(selected: 'all' | SubsurveyNode) {
    setSelectedSubsurvey(selected);
    // clear fetched survey progress data
    setSurveyProgressNumbers({});
    setMostAnswers(1);
  }

  return (
    <div>
      <h1 className='px-4 py-2'>{'Survey Viewer' + (source === 'robonav' ? ' (RoboNav)' : '')}</h1>
      <div>
        {(isLoading || subsurveyPaths === null)
          ? <SpacedSpinner className='m-4 p-2' />
          : (
            <div className='w-max'>
              <div className='flex pl-4 z-10 sticky top-0 left-0 bg-white py-3 shadow-md w-screen'> {/* z-index here to ensure dropdown stays on top */}
                <div>
                  <h4>Survey</h4>
                  <Dropdown
                    direction="right"
                    label={selectedSubsurvey ? (selectedSubsurvey === 'all' ? 'All Subsurveys' : selectedSubsurvey.name) : 'Select survey'}
                    options={[ // special case 'all', for the rest just use the list of subsurveys we fetched
                      { label: <div>All Subsurveys</div>, callback: () => selectSubsurvey('all') },
                      ...subsurveyPaths.map(subSurvey => {
                        return { label: <div>{subSurvey.name}</div>, callback: () => selectSubsurvey(subSurvey) }
                      })
                    ]} />
                </div>
                <fieldset className='ml-4'>
                  <legend style={{ width: '500px' }}>Enter specific UIDs (comma, line, or space separated) to check progress of, or leave blank to get numbers for all participants</legend>
                  <textarea value={uids} onChange={(e) => setUids(e.target.value)} className="block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:text-sm rounded-md" />
                </fieldset>
                <div className='ml-3'>
                  <fieldset className='grid grid-cols-2 grid-rows-2'>
                    <legend className='font-bold'>
                      Exclude Additional Question Types
                    </legend>
                    {EXCLUDABLE_TYPES_ARRAY.map((t) =>
                      <Form.Check
                        checked={excludedTypes[t]}
                        onChange={(e: any) => updateExcludedTypes(t)}
                        type='checkbox'
                        name={'exclude' + t}
                        id={t + 'Checkbox'}
                        label={t}
                      />
                    )}
                  </fieldset>
                </div>
                {source === 'robonav' && (
                  <div className='ml-3 bg-yellow-100 rounded-xl p-2'>
                    <div className='max-w-[400px] text-yellow-700'>
                      ⚠️ Heads-up! You are in "RoboNav" mode which is for automated testing.
                      To see progress numbers from actual applicants, <a href={`/condensed_view/program/${selectedSubsurvey ? (selectedSubsurvey === 'all' ? 'all' : selectedSubsurvey.name) : ''}`}>click here</a>
                    </div>
                  </div>
                )}
              </div>
              {selectedSubsurvey &&
                (<div className='flex p-4'>
                  {((typeof selectedSubsurvey === 'string' && selectedSubsurvey === 'all') ? subsurveyPaths : [selectedSubsurvey]).map((subsurveyNode, i) => {
                    return (
                      <div className='w-64 h-64 bg-gray-100 m-2 h-max p-2' style={{ minWidth: '750px' }} key={subsurveyNode.name + i}>
                        <h2>{subsurveyNode.name}</h2>
                        <button className={BUTTON_CLASS} disabled={isLoadingNumbers} onClick={async () => {
                          setIsLoadingNumbers(true);
                          await fetchSurveyProgressNumbers(subsurveyNode.targetFields)
                        }}>
                          Progress Numbers
                        </button>
                        {isLoadingNumbers && <SpacedSpinner className='ml-2' />}
                        {renderSubNodeOuter(subsurveyNode.subNodes)}
                      </div>
                    )
                  })}
                </div>)
              }
            </div>
          )}
      </div>
    </div>
  );
}
