import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { get_deployment, usePost } from "./API";
import { removeDuplicateEvents, safeParse, useInterval } from "./Util";
import { useMultipartUpload } from "./utils/useMultipartUpload";
import { ChangeSet, State, UserActivity } from "@aidkitorg/typesheets/lib/collab";
import { ConfigurationContext, UserInfoContext } from "./Context";
import { BaseRealtimeEvent, useChannel } from "./Realtime";
import { useToast } from "@aidkitorg/component-library";
import { compress, decompress } from "./utils/compress";
import { v4 as uuidv4 } from 'uuid';

export type DistroPartialChangeEvent = BaseRealtimeEvent<
  'partial_change',
  {
    compressedChunk: string,
    totalChunks: number,
    userActivity?: UserActivity,
    uuid: string
  }
>;

export type DistroChangeEvent = BaseRealtimeEvent<
  'change',
  {
    events: ChangeSet,
    userActivity?: UserActivity,
    uuid: string
  }
>;

export type DistroEnableCollabEvent = BaseRealtimeEvent<
  'enable_collab',
  {
    tabId: string,
    uid?: string,
    tags?: string[],
    refreshContext?: (() => void),
  }
>;

export type DistroDisableCollabEvent = BaseRealtimeEvent<
  'disable_collab',
  {
    tabId: string,
    uid?: string,
    tags?: string[],
    refreshContext?: (() => void),
  }
>;

export type DistroSavedSurveyEvent = BaseRealtimeEvent<
  'saved_survey',
  {
    changesSinceLastPublish: any,
    username?: string,
    tabId: string,
  }
>;

export type DistroRemoveUserEvent = BaseRealtimeEvent<
  'remove_user',
  {
    name: string,
    browserTab: string,
  }
>;

export type ConfigRealtimeEvents =
    DistroPartialChangeEvent
    | DistroChangeEvent
    | DistroEnableCollabEvent
    | DistroDisableCollabEvent
    | DistroSavedSurveyEvent
    | DistroRemoveUserEvent;

export function useCollabActions(
  surveyName: string, 
  tabId: string,
  mergeReceivedEventsCallback: (events: ChangeSet, userActivity?: UserActivity[], initialize?: boolean) => void,
) {
  const user = useContext(UserInfoContext);
  const config = useContext(ConfigurationContext);
  let channel = get_deployment() + ":" + surveyName;

  // triggers the backend to autosave a collab version - ie, merge the dynamo events with 
  // the current s3 collab version, prune them to smallest necessary, and save that as the new version.
  const autoSaveCollabVersion = usePost('/survey/trigger_collab_autosave');
  // get collab events and settings
  const getCollabEvents = usePost('/survey/get_collab_events');
  // disable collab
  const clearCollabEvents = usePost('/survey/clear_collab_events');
  // save events from the client as a new collab version. Used for migrating onto collab mode, as well as for 
  // very large collections of changes (like when someone copies and pastes something into the distro).
  const saveCollabVersion = usePost('/survey/save_collab_version', { compressRequestPayload: true });

  const multipartUpload = useMultipartUpload();

  // Save LOCAL Collab events to Dynamo
  const saveCollabEvents = usePost('/survey/save_collab_events', { compressRequestPayload: true, keepAlive: true });
  // Your fresh Collab changes that still need to be persisted to Dynamo
  const eventsToSave = useRef<ChangeSet>();
  const saveEventsTimer = useRef<number | undefined>(undefined);

  // Your fresh Collab changes that still need to be shared over Websockets
  const eventsToSend = useRef<ChangeSet>();
  const sendEventsTimer = useRef<number | undefined>(undefined);

  // Chunks of very large Collab change that are in the process of being received.
  // After final chunk is received, we merge events/state in Distro and this ref is cleared.
  // See: partial_change below
  const eventsAwaitingMerge = useRef<Record<string, ChangeSet[]>>({});

  // counts 3 seconds after the last incoming edit, to re-enable publishing.
  const incomingEditsTimer = useRef<number | undefined>(undefined);

  const [collabEnabled, setCollabEnabled] = useState(false);
  const [canPublish, setCanPublish] = useState(true);
  const [changesSinceLastPublish, setChangesSinceLastPublish] = useState(0);

  const { toast } = useToast();

  const getCollabState = useCallback(async () => {
    const collabResp = await getCollabEvents({ name: surveyName });
    if (collabResp && collabResp.collabEnabled) {
      setCollabEnabled(true);
      setChangesSinceLastPublish(collabResp.changesSinceLastPublish);
    } else {
      setCollabEnabled(false);
    }

    let versionEvents: ChangeSet = [];
    if (collabResp.savedVersionURL.length > 0) {
      const response = await fetch(collabResp.savedVersionURL);
      versionEvents = safeParse(await response.text(), []) as ChangeSet;
    }

    return {
      ...collabResp,
      events: [...versionEvents, ...collabResp.events]
    };
  }, [surveyName, getCollabEvents]);

  const eventCallback = useCallback(async (realtimeEvent: ConfigRealtimeEvents) => {
    switch(realtimeEvent.event) {
      case 'enable_collab': // Fall through
      case 'disable_collab':
        if (user.uid !== realtimeEvent.data.uid || tabId !== realtimeEvent.data.tabId) {
          location.reload();
        }
        break;
      case 'saved_survey':
        if (realtimeEvent.data.username !== config?.user?.name || realtimeEvent.data.tabId !== tabId) {
          setChangesSinceLastPublish(prev => realtimeEvent.data.changesSinceLastPublish === undefined ? prev : realtimeEvent.data.changesSinceLastPublish);
          const collabResp = await getCollabState();
          const userActivity = Object.keys(collabResp.userActivity || {})
            .map(k => {
              return {
                lastEditedObject: collabResp.userActivity[k].lastEditedObject,
                uid: collabResp.userActivity[k].uid,
                name: collabResp.userActivity[k].name,
                tabId: collabResp.userActivity[k].tabId
              }
            });
          mergeReceivedEventsCallback(collabResp.events, userActivity, true);
          toast({
            description: `${realtimeEvent.data.username} just published!`,
            variant: 'success'
          })
        }
        break;
      case 'change':
        if (realtimeEvent.data.events?.length) {
          setCanPublish(false);
          setChangesSinceLastPublish(prev => prev + 1);
        }

        console.log("Merging", realtimeEvent.data.uuid, realtimeEvent.data.events.length, realtimeEvent.data.userActivity);
        mergeReceivedEventsCallback(realtimeEvent.data.events, realtimeEvent.data.userActivity ? [realtimeEvent.data.userActivity] : undefined);
        break;
      case 'partial_change':
        if (realtimeEvent.data.compressedChunk?.length) {
          setCanPublish(false);
        }

        const currentlyCollected = eventsAwaitingMerge.current[realtimeEvent.data.uuid] || [];
        const decompressedEvents = decompress(realtimeEvent.data.compressedChunk) as ChangeSet;
        eventsAwaitingMerge.current[realtimeEvent.data.uuid] = [...currentlyCollected, decompressedEvents];

        // When all chunks have come in, we can go ahead and merge.
        if (eventsAwaitingMerge.current[realtimeEvent.data.uuid]?.length === realtimeEvent.data.totalChunks) {
          const flattenedEvents = eventsAwaitingMerge.current[realtimeEvent.data.uuid].reduce((acc, value) => acc.concat(value), []);
          console.log("Merging chunked changes", realtimeEvent.data.uuid, flattenedEvents.length, realtimeEvent.data.userActivity);
          mergeReceivedEventsCallback(flattenedEvents, realtimeEvent.data.userActivity ? [realtimeEvent.data.userActivity] : undefined);

          eventsAwaitingMerge.current[realtimeEvent.data.uuid] = [];
          setChangesSinceLastPublish(prev => prev + 1);
        }
        break;
      default: {
        // Ignore unexpected event
        return;
      }
    }

    if (incomingEditsTimer.current) clearTimeout(incomingEditsTimer.current);
    incomingEditsTimer.current = window.setTimeout(() => {
      setCanPublish(true);
    }, 3000);
  }, [config?.user?.name, toast, user.uid, mergeReceivedEventsCallback, getCollabState, tabId]);

  const sendEvent = useChannel<ConfigRealtimeEvents>('distro', channel, eventCallback);

  const alertJustPublished = useCallback(async (changesSinceLastPublish: number) => {
    sendEvent({
      realm: 'distro',
      channel,
      event: 'saved_survey',
      data: {
        changesSinceLastPublish: changesSinceLastPublish,
        username: config?.user?.name,
        tabId
      }
    });
  }, [sendEvent, config?.user?.name, tabId, channel]);

  const doSendRealtimeEvents = useCallback(async (myActivity?: UserActivity, uuid1?: string) => {
    const toSend = removeDuplicateEvents(eventsToSend.current || []);
    console.log('Sending events', uuid1, JSON.stringify(toSend));
    // 100 events has a payload length of roughly 30,000. We have a 32,768 length (32 KB) limit on our production websocket API.
    // If we have more than 100 events to send, let's split and compress them.
    if (toSend.length > 100) {
      // we can compress 700 events into ~29,000, which is small enough to send.
      const chunkSize = 700;
      const numChunks = Math.ceil(toSend.length / chunkSize);
      for (let i = 0; i < toSend.length; i += chunkSize) {
        const chunk = toSend.slice(i, i + chunkSize);
        const compressedChunk = compress(chunk);
        sendEvent({
          realm: 'distro',
          channel,
          event: 'partial_change',
          data: {
            compressedChunk: compressedChunk,
            totalChunks: numChunks,
            userActivity: myActivity,
            uuid: uuid1 || ''
          }
        });
        // This is needed at the moment in order for the listening websocket to correctly 
        // receive eveything. Not sure if this is just a dev thing...
        await new Promise(resolve => setTimeout(resolve, 500));
      }
    } else {
      sendEvent({
        realm: 'distro',
        channel,
        event: 'change',
        data: {
          events: toSend,
          userActivity: myActivity,
          uuid: uuid1 || ''
        }
      });
    }
    eventsToSend.current = undefined;
  }, [sendEvent, channel]);

  const writeNewCollabVersion = useCallback(async (params: {toSend: string, migrating?: boolean }) => {
    const { toSend, migrating } = params;
    const partSize = 5_000_000; // ~5MB
    // decide if the events toSend are too big to send over https. If so, 
    // we use multipart upload to send them in chunks straight to s3.
    const numParts = Math.ceil(toSend.length / partSize);
    if (numParts > 1) {
      const fileName = `${surveyName}/collabEvents.txt`; // Choose an appropriate file name
      const file = new File([toSend], fileName, { type: "text/plain" });

      const res = await multipartUpload.startUpload(file, {
        pathParams: {
          kind: 'collab',
          survey: surveyName
        },
        contentType: 'text/plain',
        length: toSend.length
      });
      
      await saveCollabVersion({
        name: surveyName,
        events: '',
        locationURL: res?.location,
        migrating
      });
    } else {
      await saveCollabVersion({
        name: surveyName,
        events: toSend,
        locationURL: '',
        migrating,
      });
    }
    return {
      status: 'ok'
    }
  }, [surveyName, saveCollabVersion, multipartUpload]);

  const enableCollab = useCallback(async (collabEvents: string) => {
    setCollabEnabled(true);

    await writeNewCollabVersion({
      toSend: collabEvents,
      migrating: true
    });

    sendEvent({
      realm: 'distro',
      channel,
      event: 'enable_collab',
      data: {
        ...user, tabId
      }
    });
  }, [sendEvent, writeNewCollabVersion, user, tabId, channel]);

  const disableCollab = useCallback(async () => {
    setCollabEnabled(false);
    await clearCollabEvents({ name: surveyName });

    sendEvent({
      realm: 'distro',
      channel,
      event: 'disable_collab',
      data: {
        ...user, tabId
      }
    });
  }, [sendEvent, clearCollabEvents, user, tabId, channel, surveyName]);

  // This function handles sending new collab events over websockets to other listening clients, 
  // and saves new collab events to dynamo (or s3, if they're really large)
  const handleNewCollabEvents = useCallback(async (events: ChangeSet, myActivity?: UserActivity, state?: State) => {
    const uuid1 = uuidv4();
    const thisRoundEvents = (eventsToSave.current ?? []).concat(events);

    if (saveEventsTimer.current) clearTimeout(saveEventsTimer.current);
    eventsToSave.current = thisRoundEvents;

    if (sendEventsTimer.current) clearTimeout(sendEventsTimer.current);
    eventsToSend.current = (eventsToSend.current ?? []).concat(events);

    sendEventsTimer.current = window.setTimeout(() => {
      doSendRealtimeEvents(state ? state.myActivity : myActivity, uuid1);
    }, 100);

    // We debounce 1 full second, then persist events to dynamo
    saveEventsTimer.current = window.setTimeout(async () => {
      const uniqueEvents = removeDuplicateEvents(thisRoundEvents);
      const toSend = JSON.stringify(uniqueEvents);
      // These collections of events can get big. If they're REALLY big, use writeNewVersion's multiupload
      // to upload them to s3 and save a new version automatically.
      if (toSend.length > 100000) {
        await writeNewCollabVersion({
          toSend
        })
      } else {
        await saveCollabEvents({
          name: surveyName,
          events: toSend,
          userActivity: state ? state.myActivity : myActivity
        });
      }

      eventsToSave.current = undefined;
    }, 1000);
  }, [doSendRealtimeEvents, writeNewCollabVersion, saveCollabEvents, surveyName]);

  useInterval(() => {
    if (collabEnabled) autoSaveCollabVersion({name: surveyName})
  }, 20 * 1000);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (collabEnabled && user.uid) {
        saveCollabEvents({
          name: surveyName,
          events: JSON.stringify([]),
          userActivity: {
            uid: user.uid,
            name: config?.user?.name,
            tabId,
            lastEditedObject: ''
          }
        });

        // Send userActivity update over websockets too, so that existing distro viewers don't see you lingering.
        doSendRealtimeEvents({
          uid: user.uid,
          name: config?.user?.name,
          tabId,
          lastEditedObject: ''
        });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [doSendRealtimeEvents, saveCollabEvents, surveyName, tabId, config?.user?.name, user, collabEnabled]);

  // https://react.dev/reference/react/useCallback#optimizing-a-custom-hook
  // "If you’re writing a custom Hook, it’s recommended to wrap any functions that it returns into useCallback"
  return {
    collabEnabled,
    canPublish,
    changesSinceLastPublish,
    alertJustPublished,
    getCollabState,
    handleNewCollabEvents,
    enableCollab,
    disableCollab,
    writeNewCollabVersion
  };
}