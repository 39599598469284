import { Text } from '@aidkitorg/types/lib/survey';
import en from './langs/en.json';
import es from './langs/es.json';
import fa_AF from './langs/fa_AF.json';
import ps_AF from './langs/ps_AF.json';
import zh_CN from './langs/zh_CN.json';
import pl from './langs/pl.json';
import ar from './langs/ar.json';
import tl from './langs/tl.json';
import yue from './langs/yue.json';
import am from './langs/am.json';
import ko from './langs/ko.json';
import so from './langs/so.json';
import vi from './langs/vi.json';
import fa from './langs/fa.json';
import pt from './langs/pt.json';
import bn from './langs/bn.json';
import ht from './langs/ht.json';
import cni from './langs/cni.json';
import pua from './langs/pua.json';
import mh from './langs/mh.json';
import fr from './langs/fr.json';
import ha from './langs/ha.json';
import ne from './langs/ne.json';
import ru from './langs/ru.json';
import om from './langs/om.json';
import hmn from './langs/hmn.json';
import ua from './langs/ua.json';

export type Translations = typeof en;
export type SupportedLanguage = keyof Omit<Text, '_id'>;

export const STRINGS: Record<SupportedLanguage, Translations> = {
  en,
  es,
  fa_AF,
  ps_AF,
  zh_CN,
  pl,
  ar,
  tl,
  yue,
  am,
  ko,
  so,
  vi,
  fa,
  pt,
  bn,
  ht,
  cni,
  pua,
  mh,
  fr,
  ha,
  ne,
  ru,
  om,
  hmn,
  ua,
};


export const SupportedLanguages = Object.keys(STRINGS) as SupportedLanguage[];

export const locals = (lang: SupportedLanguage) => {
  return (STRINGS[lang] || STRINGS['en']).api;
}

export const getLocalizedRecordByKey = (key: keyof Translations['api']) => {
  return SupportedLanguages.reduce((acc, lang) => {
    acc[lang as SupportedLanguage] = STRINGS[lang as SupportedLanguage]['api'][key];
    return acc;
  }, {} as Record<SupportedLanguage, string>);
}

export const normalizeLanguage = (lang?: string) => {
  if (!lang) return undefined;
  if (SupportedLanguages.indexOf(lang as SupportedLanguage) !== -1) {
    return lang as SupportedLanguage;
  }
  return ({
    'english': 'en',
    'spanish': 'es',
    'pashto': 'ps_AF',
    'dari': 'fa_AF',
    'chinese': 'zh_CN',
    'mandarin': 'zh_CN',
    'polish': 'pl',
    'arabic': 'ar',
    'tagalog': 'tl',
    'filipino': 'tl',
    'cantonese': 'yue',
    'amharic': 'am',
    'korean': 'ko',
    'somali': 'so',
    'vietnamese': 'vi',
    'persian': 'fa',
    'portuguese': 'pt',
    'bengali': 'bn',
    'haitian_creole': 'ht',
    'ashaninka': 'cni',
    'purepecha': 'pua',
    'marshallese': 'mh',
    'french': 'fr',
    'hausa': 'ha',
    'nepali': 'ne',
    'russian': 'ru',
    'oromo': 'om',
    'hmong': 'hmn',
    'ukrainian': 'ua',
  } as Record<string, SupportedLanguage | undefined>)[lang.toLowerCase()];
}

export const LanguageNames: Record<SupportedLanguage, string> = {
  'am': 'Amharic',
  'ar': 'Arabic',
  'bn': 'Bengali',
  'cni': 'Asháninka',
  'en': 'English',
  'es': 'Spanish',
  'fa': 'Persian',
  'fa_AF': 'Dari',
  'fr': 'French',
  'ha': 'Hausa',
  'ht': 'Haitian Creole',
  'ko': 'Korean',
  'mh': 'Marshallese',
  'ne': 'Nepali',
  'om': 'Oromo',
  'pl': 'Polish',
  'ps_AF': 'Pashto',
  'pt': 'Portuguese',
  'pua': 'Purépecha',
  'ru': 'Russian',
  'so': 'Somali',
  'tl': 'Tagalog',
  'vi': 'Vietnamese',
  'yue': 'Cantonese',
  'zh_CN': 'Chinese (Simplified)',
  'hmn': 'Hmong',
  'ua': 'Ukrainian',
};

/**
 * This maps our lang code to the lang code used by various services
 * If the code is not here for the other service, it probably uses the same
 * code as us, or it uses zh-CN for example instead of zh_CN so we just need to replace _ with -
 */
export const LANG_MAP: Record<SupportedLanguage, { 
  code?: string, voice?: string, twilio?: string | { sms?: string, voice?: string } 
}> = {
  'en': { code: 'en-US', voice: 'Kendra' },
  'es': { code: 'es-US', voice: 'Lupe' },
  'zh_CN': { code: 'cmn-CN', voice: 'Zhiyu' },
  'ar': { code: 'arb', voice: 'Zeina', twilio: { sms: 'ar', voice: 'en' } },
  'pl': { code: 'pl-PL', voice: 'Ewa' },
  'fr': { code: 'fr-FR', voice: 'Celine' },
  'am': { twilio: 'en' },
  'so': { twilio: 'en' },
  'hmn': { twilio: { sms: 'vi', voice: 'en' } },
  'bn': { twilio: 'en' },
  'cni': { twilio: 'es' },
  'fa': { twilio: 'en' },
  'fa_AF': { twilio: 'en' },
  'ha': { twilio: 'en' },
  'ht': { twilio: 'en' },
  'ko': { },
  'mh': { twilio: 'es' },
  'ne': { twilio: 'en' },
  'om': { twilio: 'en' },
  'ps_AF': { twilio: 'en' },
  'pt': { },
  'pua': { twilio: 'es' },
  'ru': { },
  'tl': { twilio: { sms: 'tl', voice: 'en' } },
  'ua': { twilio: 'ua' },
  'vi': { twilio: { sms: 'vi', voice: 'en' }},
  'yue': { twilio: 'zh-HK' },
}