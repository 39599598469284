import { useCallback, useEffect, useRef, useState } from "react";
import { useToken } from "./API";
import { useInterval } from "./Util";

export const PUBSUB = process.env.NODE_ENV === 'development' 
  ? "ws://localhost:7777"
  // ? "wss://anne.goat-mamba.ts.net:7778" // use this instead for websockets in goat mamba land
  : "wss://aklxl0yqhl.execute-api.us-east-2.amazonaws.com/prod";

export type BaseRealtimeEvent<T extends string, D> = {
  realm: string;
  channel: string;
  event: T;
  data: D;
};

export function useChannel<T extends BaseRealtimeEvent<string, any>>(realm: string, name: string, callback: (realtimeEvent: T) => void) {
  const reconnectTimeout = useRef<NodeJS.Timeout | undefined>();
  const reconnectAttempt = useRef(0);
  const queuedMessagesRef = useRef<string[]>([]);
  const socket = useRef<WebSocket>();
  // Keep callback in a ref so we don't recreate the entire webhook (via setup) each time a "new" callback is passed in.
  // This means components don't have to memoize and manage their rerendering logic to use this hook without causing
  // excessive creation/tear-downs of the websocket.
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const [,token] = useToken();
    
  const setup = useCallback(() => {
        
    if (socket.current) {
      socket.current.close();
    }

    if (!name) return;

    console.log('Creating websocket', realm, name);

    socket.current = new WebSocket(PUBSUB + "?" + new URLSearchParams({ realm, channel: name, token: (token as string).replace('auth=','') }).toString());
    socket.current.onerror = (event) => {
      console.warn("Error connecting to pubsub", event);
    }
    socket.current.onopen = (event) => {
      console.log("Connected to pubsub", event);
      // Reset attempt if we successfully connect
      reconnectAttempt.current = 0;

      // TODO: this approach is a bit naive and simplistic. I'm not sure we want to go full CRDT here,
      // but what is here may be insufficient
      // flush any queued messages
      queuedMessagesRef.current.forEach((message) => {
        socket.current?.send(message);
      })
      queuedMessagesRef.current = [];
    }
    socket.current.onmessage = (event) => {
      try {
        callbackRef.current(JSON.parse(event.data));
      } catch (error) {
        console.error("Error parsing pubsub message", error, (event as any).data);
        throw new Error("Error in Realtime " + realm + ":" + name)
      }
    }

    socket.current.onclose = (event) => {
      console.log("Pubsub connection closed", event);

      // try to reopen. As long as useChannel has been called, we want to keep the conn open.
      // Code 1000 and 1001 are expected close events (codes <1000 are unused), so no need to try to reopen if those occur.
      if (event.code > 1001 && !document.hidden) {
        // Delay is 100ms * 2^(attempt #) multiplied by a random amount between 1 and 1.2 with a max of 30 seconds.
        const delay = Math.min(100 * (2 ** (reconnectAttempt.current - 1)), 30000) * (1 + (Math.random() * 0.2));

        // No need to go beyond 9 as it hits our 30 second max.
        if (reconnectAttempt.current < 9) {
          reconnectAttempt.current += 1;
        }
        console.warn(`Pubsub connection closed. Will try again in ${delay / 1000} seconds`);
        
        reconnectTimeout.current = setTimeout(() => {
          // Check one more time that the socket is closed before attempting to reopen.
          if (socket.current?.readyState !== WebSocket.OPEN) {
            setup();
          }
        }, delay);
      }
    }
  }, [name, realm, token]);

  const send = useCallback((event: T) => {
    const message = JSON.stringify(event);
    if (socket.current?.readyState === WebSocket.OPEN) {
      socket.current?.send(message);
      return { status: 'success' };
    } else {
      queuedMessagesRef.current.push(message);
      return { status: 'queued' };
    }
  }, []);

  useEffect(() => {
    setup();
    return () => {
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      socket.current?.close();
    }
  }, [setup]);

  return send;
}

export function stringToColor(str: string, dark?: boolean) {
  if (!str) return 'hsl(0%,0%,0%)';

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${(hash % 360)}, 60%, ${dark ? '85%' : '90%'})`;
}

export const inits = (str: string) => {
  if (str) return str.split('/')[0].split(' ').filter((v: string) => v).map((w: string) => w[0]).join('');

  return '';
}

export function FacePile(props: { name: string, channel: string, unsavedChanges?: () => boolean, browserTab?: string }) {
  const [userList, setUserList] = useState({} as Record<string, { name: string, lastSeen: number, unsavedChanges?: boolean, browserTab?: string }>);
  const [warned, setWarned] = useState(false);

  const facePileCallback = useCallback((data: any) => {
    // console.log("Data:", data);
    if (data.event === "heartbeat") {
      setUserList((lastSeen) => ({ ...lastSeen, [data.data.name + '/' + data.data.browserTab]: { lastSeen: Date.now(), ...data.data }}));
    }

    if (data.event === "remove_user") {
      setUserList((lastSeen) => {
        const newUsers = lastSeen;
        delete newUsers[data.data.name + '/' + data.data.browserTab];
        return newUsers;
      });
    }
  }, []);

  const sendData = useChannel("facepile", props.channel, facePileCallback);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      sendData({
        realm: 'facepile',
        channel: props.channel,
        event: 'remove_user',
        data: {
          name: props.name,
          browserTab: props.browserTab
        }
      }); 
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [sendData, props.name, props.browserTab, props.channel]);

  useInterval(() => {
    let unsaved = props.unsavedChanges?.() || false;
    if (props.name && document.visibilityState === "visible") {
      sendData({
        realm: 'facepile',
        channel: props.channel,
        event: 'heartbeat',
        data: {
          name: props.name,
          unsavedChanges: unsaved,
          browserTab: props.browserTab
        }
      }); 
    }
    const ul = userList;
    const otherPeopleEdits = Object.keys(ul).map((n) => ul[n]).filter(u => u.unsavedChanges && u.name !== props.name).length > 0;
    const otherTabEdits = Object.keys(ul).map((n) => ul[n]).filter(u => u.unsavedChanges && u.name === props.name && u.browserTab !== props.browserTab).length > 0;
    // console.log(otherPeopleEdits, otherTabEdits)
    if (unsaved && !warned && (otherPeopleEdits || otherTabEdits)) {
      if (otherPeopleEdits) {
        alert("Warning! You're making a change when someone else has outstanding edits!");
      }
      if (otherTabEdits) {
        alert("Warning! You're making a change when you have edits in a separate tab!");
      }
      setWarned(true);
    }
    if (!otherPeopleEdits && !otherTabEdits) {
      setWarned(false);
    }
  }, 1000);
    
  return <div className="inline-block">{Object.keys(userList).map((k) => {
    let extraStyle = {}
    let extraTitle = ''
    if (userList[k].unsavedChanges) {
      extraStyle = { border: '2px solid red' };
      extraTitle = ' - Unsaved Changes '
    }
    // When we haven't had a heartbeat from someone in 20 seconds, show them greyed out.
    if (Date.now() - userList[k].lastSeen > 20000) {
      extraStyle = { ...extraStyle, opacity: 0.5 };
    }
    return <div key={k} title={`${k.split('/')[0]} ${extraTitle}(${userList[k].browserTab})`} className={"inline-block ml-2 p-1 radius-lg text-white rounded-full cursor-pointer "} style={{backgroundColor: stringToColor(k), ...extraStyle}}>{inits(k)}</div>
  })}</div>
}