import { BooleanExpr, GenericTemplatedBlock, GiveCardMailing, NotificationGroup, Payment, ValueExpr } from "./survey";
import { CompileExpressionToEnglish } from "./translation/expr_to_english";
import { CompileExpressionToJS } from "./translation/expr_to_js";
import { findFields } from "./translation/expr_to_sql";
import { expandGenericTemplates } from "./translation/templates";
import { hash } from "./translation/v0_to_legacy";
import { Countable } from "./traverse";

export function wrappedFn<T>(fnStr: string, safeReturn: T, params?: {
  args?: any[]
}) {
  let fn;
  let result: boolean | string;
  let error;
  try {
    fn = Function(fnStr)();
  } catch (e) {
    console.error(`wrappedFn parse error: ${fnStr}`);
    return safeReturn;
  }

  try {
    result = fn(...params?.args || []);
  } catch (e) {
    // Console log must come AFTER unmock or we may get weird log timestamps.
    console.log('wrappedFn compute error:', e);
    result = '';
    error = e instanceof Error ? e.message : (e as string);
  }

  return { result, error };
}

export function evalDistro(expression: ValueExpr, info: Record<string, string | undefined>) {
  // Evaluate 
  const expr = CompileExpressionToJS(expression);
  const wrappedResult = wrappedFn(
    "return (function(info, org, screener, view_info) { const out = " + expr + "; return out });",
    null,
    {
      args: [info, {}, {}, {}]
    }
  );


  if (wrappedResult === null || wrappedResult.error) {
    console.error("Error processing distro conditional:", expr, "returning true: ", wrappedResult);
    return true;
  }

  return wrappedResult.result;
}

export function evalDistroConditional(conditional: BooleanExpr, info: Record<string, string | undefined>) {
  // Evaluate 
  const expr = CompileExpressionToJS(conditional);
  const wrappedResult = wrappedFn(
    "return (function(info, org, screener, view_info) { const out = " + expr + "; return out });",
    null,
    {
      args: [info, {}, {}, {}]
    }
  );

  if (wrappedResult === null || wrappedResult.error) {
    console.error("Error processing distro conditional:", expr, "returning true: ", wrappedResult);
    return true;
  }

  return !!wrappedResult.result;
}

export type ConditionResultExplanation = {
  cond: BooleanExpr | GenericTemplatedBlock<BooleanExpr[]>,
  relatedTargetFields?: Record<string, string>,
  passed?: string[],
  failed?: string[],
  explanations?: ConditionResultExplanation | ConditionResultExplanation[]
};

/**
 * Traverses a Distro Expression and evaluates each node in the tree.
 *
 * The top-most result holds all of the relevant contributions to the final result (e.g. all failures and passes).
 *
 * Related Fields are extracted along the way, and also will be collected in the top-most result. 
 */
export function explainCondition(
  condOrTemplate: BooleanExpr | GenericTemplatedBlock<BooleanExpr[]>,
  appInfo: Record<string, string>,
  summarize: (expr: BooleanExpr) => string = CompileExpressionToEnglish): ConditionResultExplanation {
  const cond: BooleanExpr = expandGenericTemplates(condOrTemplate);
  let pass = evalDistroConditional(cond as any, appInfo);

  function valueCond(valueExpr: ValueExpr, compareExpr?: ValueExpr) {
    const relatedTargetFields = findFields(valueExpr);
    const relatedCompareFields = compareExpr ? findFields(compareExpr) : [];
    const test = [summarize(cond)];
    return {
      cond,
      relatedTargetFields: Object.fromEntries(relatedTargetFields.concat(relatedCompareFields).map(tf => [tf, appInfo[tf] ?? null])),
      passed: pass ? test : undefined,
      failed: !pass ? test : undefined,
    }
  }

  switch (cond.kind) {
    case 'And':
    case 'Or': {
      const inner = cond.clauses.map(c => explainCondition(c, appInfo));

      let failed: string[] = [];
      let passed: string[] = [];
      for (const test of inner) {
        failed = failed.concat(test.failed || []);
        passed = passed.concat(test.passed || []);
      }

      const actuallyPassed = cond.kind === 'Or' ? passed.length : !failed.length;

      return {
        cond,
        passed: actuallyPassed ? passed : [],
        failed: !actuallyPassed ? failed : [],
        relatedTargetFields: inner.map(i => i.relatedTargetFields ?? {}).reduce((prev, cur) => Object.assign(prev, cur), {}),
        explanations: inner
      };
    }
    case 'Not':
      const inner = explainCondition(cond.clause, appInfo);
      return {
        cond: cond.clause,
        // inverted on purpose!
        passed: inner.failed?.map(f => `NOT(${f})`),
        failed: inner.passed?.map(f => `NOT(${f})`),
        relatedTargetFields: inner.relatedTargetFields,
        explanations: inner
      }
    case 'Equals':
    case 'Not Equal':
    case 'DoesntExist':
    case 'Exists':
    case 'Last Modified':
    case 'Last Modified Date':
      const test = [summarize(cond)];
      return {
        cond,
        relatedTargetFields: { [cond.field]: appInfo[cond.field] ?? null },
        passed: pass ? test : undefined,
        failed: !pass ? test : undefined,
      };
    case 'Value Empty':
    case 'Value Not Empty':
      return valueCond(cond.value);
    case 'Value Not Equal':
      return valueCond(cond.value, cond.notEqual);
    case 'Value Equals':
      return valueCond(cond.value, cond.equals);
    case 'Value Greater Than':
      return valueCond(cond.value, cond.greaterThan);
    case 'Value Less Than':
      return valueCond(cond.value, cond.lessThan);
    case 'Value Contains':
      return valueCond(cond.value, cond.contains);
    case 'Code Boolean Expr':
    case 'Before':
    case 'After': {
      const test = [summarize(cond)];
      return {
        cond,
        passed: pass ? test : undefined,
        failed: !pass ? test : undefined,
      }
    }
    case 'Never':
      return {
        cond,
        failed: ['never (false)']
      }
    default:
      let _: never = cond;
  }

  return {
    cond: { kind: 'Never' },
    failed: ['wtf']
  };
}

export type HasEnableKey = Extract<
  Exclude<Countable, { kind: 'Cross Program Data Exchange' }>
  // stand-in for Followups, prior to them having their own Distro Type.
  | Required<NotificationGroup>['followups'][number] & { kind: 'Notification Followup', targetPrefix: string },

  // grabs all types from Countable that have an enableKey
  { enableKey: string }
>;

export function calcEnableKey(selected: HasEnableKey) {

  const parts = (() => {
    switch (selected.kind) {
      case 'GiveCard Mailing':
        return [
          selected.targetField,
          JSON.stringify(selected.condition)
        ];
      case 'Payment':
        return [
          selected.targetField,
          JSON.stringify(selected.condition),
          (selected.ledger ? typeof selected.ledger === 'string' ? selected.ledger : selected.ledger.field : '')
        ];
      case 'Notification':
        return [
          selected.targetPrefix,
          JSON.stringify(selected.initial_notification.enabled_when)
        ];
      case 'USBank Card Mailing':
        return [
          selected.targetField,
          JSON.stringify(selected.condition)
        ];
      case 'Notification Followup': {
        return [
          selected.targetPrefix,
          selected.suffix,
          JSON.stringify(selected.send_if)
        ];
      }
      case undefined:
        return [];
      default:
        let _: never = selected;
        return [];
    }
  })();

  return hash(parts.join(''));
}

export function paymentExtraChecks(selected: Payment | GiveCardMailing): BooleanExpr {
  return {
    kind: 'And',
    clauses: [
      {
        kind: 'Not Equal',
        field: 'blocked_payments',
        value: 'yes'
      },
      {
        kind: 'Or',
        clauses: [{
          kind: 'DoesntExist',
          field: selected.targetField
        }, {
          kind: 'Equals',
          field: selected.targetField,
          value: 'attempt_0'
        }, {
          kind: 'Equals',
          field: selected.targetField,
          value: ''
        }]
      },
      {
        kind: 'And',
        clauses: [
          {
            kind: 'Not Equal',
            field: 'stage',
            value: 'Correction Requested'
          },
          {
            kind: 'Or',
            clauses: [
              {
                kind: 'Not Equal',
                field: 'stage',
                value: 'Ineligible'
              },
              {
                kind: 'Equals',
                field: '_ignore_ineligible_stage',
                value: 'yes'
              }
            ]
          }
        ]
      }
    ]
  }
}
