import { useToast } from "@aidkitorg/component-library";
import { Section } from "@aidkitorg/types/lib/legacy/airtable";
import * as v0 from "@aidkitorg/types/lib/survey";
import { AirtableSurveyToSurveyDefinition, expandTemplates, v0ToLegacy } from "@aidkitorg/types/lib/translation/v0_to_legacy";
import { Author, ChangeSet, generateUserLocation, getCompressedEvents, mergeStateFromEvents, Patch, reconstructStateFromEvents, State, updateUserActivity, UserActivity } from "@aidkitorg/typesheets/lib/collab";
import { captureException } from "@sentry/react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { get_deployment, usePost } from "./API";
import { ModularQuestionPage } from "./Apply";
import { ClickableButton } from "./Components/Button";
import { Dropdown } from "./Components/Dropdown";
import { ConfigRealtimeEvents } from "./CollabWrapper";
import InterfaceContext, { ConfigurationContext, SupportedLanguage, UserInfoContext } from "./Context";
import { FacePile, useChannel } from "./Realtime";
import { enumerateSubsurveys, generateRandomString, removeDuplicateEvents, safeParse } from "./Util";
import { compress, decompress } from "./utils/compress";
import { decompressSync, strFromU8 } from "fflate";

export function InlineEditor() {
  const userContext = useContext(UserInfoContext);
  const config = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [tabId, setTabId] = useState(generateRandomString(4, 'abcdefghjkmnpqrstuvwxyz123456789'));
  const [showEdits, setShowEdits] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const [survey, setSurvey] = useState<any>(null);
  const [subsurveys, setSubsurveys] = useState<v0.Subsurvey[]>([]);
  const [canPublish, setCanPublish] = useState(true);

  // Your Collab changes that have not been shared over WebSockets nor saved to Dynamo
  const eventsToSend = useRef<ChangeSet>();
  const sendEventsTimer = useRef<NodeJS.Timeout>();

  // Your Collab changes that have not been saved to Dynamo, but may have been shared via WebSockets
  const eventsToSave = useRef<ChangeSet>();
  const saveEventsTimer = useRef<NodeJS.Timeout>();

  // Chunks of very large Collab change that are in the process of being received.
  // After final chunk is received, we merge events/state in Distro and this ref is cleared.
  // See: partial_change below
  const eventsAwaitingMerge = useRef<Record<string, ChangeSet[]>>({});

  // Save pruned Collab events to S3.
  // Note: This is the collab version of saving a survey, but it does not directly
  // update the definitive survey JSON that defines the program; that happens via
  // editor.tsx calling read() on its root TrackedObject and passing that back to Config.tsx
  // via onChange/handleUpdatesInner, which sets the survey State variable in Config
  // which is what is used to publish/save the survey when the user hits Publish
  const autoSaveCollabVersion = usePost('/survey/save_collab_version', { compressRequestPayload: true });

  // Save LOCAL Collab events to Dynamo
  const saveCollabEvents = usePost('/survey/save_collab_events', { compressRequestPayload: true, keepAlive: true });

  const getCollabEvents = usePost('/survey/get_collab_events');

  const incomingEditsTimer = useRef<NodeJS.Timeout>();
  const stateRef = useRef<State>();
  // This needs to be a ref in order to be retained in sendEvent() (TODO: tabId seems to work fine though...)
  const selectedSubsurveyPathRef = useRef<string>();
  const [author, setAuthor] = useState<Author>();
  const lastActivityTime = useRef(Date.now());
  const nextAutosaveTime = useRef(Date.now() + (20 * 1000));

  const [collabEnabled, setCollabEnabled] = useState(true);

  const channel = get_deployment() + ':entireprogram';

  const { toast } = useToast();

  const [airtableSubsurvey, setAirtableSubsurvey] = useState<{ sections: Section[], viewableFields: string[] }>();
  const [mockInfo, setMockInfo] = useState<any>({});

  function selectSubsurvey(subsurvey: v0.Subsurvey) {
    selectedSubsurveyPathRef.current = subsurvey.path;
    const airtableSubsurvey = v0ToLegacy(subsurvey.sections);
    setAirtableSubsurvey(AirtableSurveyToSurveyDefinition(airtableSubsurvey, true));
  }

  // General Initialization
  useEffect(() => {
    if ((userContext?.tags ?? []).some((tag) => ['inline_edit', 'admin'].includes(tag))) {
      if (!author) {
        setAuthor({ uid: userContext.uid, tags: userContext.tags, tabId });
      }
      setCanEdit(true);
      if (!survey) {
        (async () => {
          const resp = await getCollabEvents({ name: 'entireprogram' });

          let versionEvents: ChangeSet = [];
          if (resp.savedVersionURL.length > 0) {
            const response = await fetch(resp.savedVersionURL);
            versionEvents = safeParse(await response.text(), []) as ChangeSet;
          }
          let [rootTrackedObject, state] = reconstructStateFromEvents([...versionEvents, ...resp.events]);
          stateRef.current = state;
          stateRef.current.currentAuthor = { uid: userContext.uid, tabId, tags: userContext.tags }
          const surveyFromCollabEvents = rootTrackedObject.read(true);
          setSurvey(surveyFromCollabEvents)
          const expandedSurvey = expandTemplates(surveyFromCollabEvents) as v0.ExpandedSurvey;
          const subsurveys = enumerateSubsurveys(expandedSurvey.survey);
          setSubsurveys(subsurveys);
        })();
      }
    }
  }, [userContext]);

  useEffect(() => {
    if (!canPublish) return;
    if (nextAutosaveTime.current >= Date.now()) return;
    if (lastActivityTime.current < Date.now() - 20000) return; // TODO(Riley): confirm this works

    nextAutosaveTime.current = Date.now() + (20 * 1000);
    let saveCollabVersionTimer: NodeJS.Timeout;
    try {
      const prunedEvents = getCompressedEvents(stateRef.current!);

      // Since everyone's browsers will be ready to save at about the same time (once all changes are merge in),
      // spread the auto save calls out a bit so someone will get there first.
      saveCollabVersionTimer = setTimeout(() => {
        autoSaveCollabVersion({
          name: 'entireprogram',
          events: JSON.stringify(prunedEvents)
        });
      }, Math.floor(5 * 1000 * Math.random()));
    } catch (e) {
      // getPrunedEvents could possibly throw an error.
      // the stateRef we have here is the configRef in distro.
      captureException(e, {
        level: 'warning', extra: {
          survey: 'entireprogram'
        }
      });
    }

    return () => clearTimeout(saveCollabVersionTimer);
  }, [canPublish]);

  /****** START: Sending Collab Events via WebSockets ******/

  // Somewhat analogous to Config.tsx's handleUpdatesInner(), i.e. a function
  // that is called whenever there is an update, in this case to context.inlineEdit.inlineEdits
  useEffect(() => {
    setCanPublish(false);

    if (sendEventsTimer.current) {
      clearTimeout(sendEventsTimer.current);
    }
    sendEventsTimer.current = setTimeout(() => {
      if (stateRef.current && author && context.inlineEdit.inlineEdits.latestEditedObjectId) {
        stateRef.current.myActivity = generateUserLocation(author, context.inlineEdit.inlineEdits.latestEditedObjectId);
      }

      const inlineEditsAsCollabEvents = convertInlineEditsToCollabEvents();

      eventsToSend.current = (eventsToSend.current ?? []).concat(inlineEditsAsCollabEvents);
      doSendEvents(stateRef.current?.myActivity);
      // We debounce 1 full second, then save events to dynamo
      eventsToSave.current = (eventsToSave.current ?? []).concat(inlineEditsAsCollabEvents);
      if (saveEventsTimer.current) {
        clearTimeout(saveEventsTimer.current);
      }
      saveEventsTimer.current = setTimeout(() => {
        writeCollabEvents('entireprogram', eventsToSave.current);
        eventsToSave.current = undefined;
      }, 1000);
    }, 500);

  }, [context.inlineEdit.inlineEdits])

  // This saves inline-edits ascollab events to dynamo
  async function writeCollabEvents(name: string, events?: ChangeSet) {
    const uniqueEvents = removeDuplicateEvents(events || []);
    const toSend = JSON.stringify(uniqueEvents);

    // These collections of events can get big. If they're REALLY big, we don't want to block up dynamo by saving them there.
    // Instead, lets auto-save immediately.
    if (toSend.length > 100000) {
      nextAutosaveTime.current = Date.now();
      return;
    }

    await saveCollabEvents({
      name,
      events: toSend
    });
  }

  function mergeEvents(events: ChangeSet, userActivity?: UserActivity) {
    if (!events.length) {
      return;
    }

    let latestRootTrackedObject;
    if (!stateRef.current) {
      [latestRootTrackedObject, stateRef.current] = reconstructStateFromEvents(eventsToSend.current || events);
    } else {
      // This function and updateUserActivity update the State that is passed into them, so we shouldn't need to reassign
      // stateRef.current here.
      latestRootTrackedObject = mergeStateFromEvents(stateRef.current, events)[0];
      if (userActivity) {
        updateUserActivity(stateRef.current, [userActivity]);
      }
    }

    // This block converts the Collab TrackedObject in to a Distro Survey and then an AirTable survey in order to
    // mirror what we do to render the surveys for participants.
    // TODO(Riley): do we actually want to expand templates? What does is really mean to edit an expanded templated block?
    // Probably we should keep things more similar to the distro editor and only allow the base template to be edited (if at all)
    const freshestSurvey = latestRootTrackedObject.read(true);
    // Note: If we called save_survey with this freshestSurvey object, that would be
    // equivalent to the "Real" save that happens upon publishing in Distro
    setSurvey(freshestSurvey);
    const expandedSurvey = expandTemplates(freshestSurvey) as v0.ExpandedSurvey;

    const subsurveys = enumerateSubsurveys(expandedSurvey.survey);
    setSubsurveys(subsurveys);
    const chosenSubsurvey = subsurveys.find(subsurvey => subsurvey.path === selectedSubsurveyPathRef.current);
    if (selectedSubsurveyPathRef.current && subsurveys?.length && !chosenSubsurvey) {
      toast({
        description: 'Selected Subsurvey was removed by another user',
        variant: 'error',
      });
      return;
    }
    if (chosenSubsurvey) {
      const airtableSurvey = v0ToLegacy(chosenSubsurvey.sections);
      setAirtableSubsurvey(AirtableSurveyToSurveyDefinition(airtableSurvey, true));
    }

    context.inlineEdit.setRefreshInlineEditSignal((prev) => prev + 1);
  }

  const sendEvent = useChannel<ConfigRealtimeEvents>('distro', channel, (realtimeEvent) => {
    switch (realtimeEvent.event) {
      case 'enable_collab': // Fall through
        toast({
          description: 'Collab enabled. Editing is now allowed',
          variant: 'success',
        });
        setCanEdit(true);
        break;
      case 'disable_collab':
        toast({
          description: 'Collab has been disabled. You cannot use inline eidting without Collab',
          variant: 'error'
        });
        setCanEdit(false);
        break;
      case 'saved_survey':
        // Clear out any inline edits because someone has just published all of the changes
        // including those made here.
        context.inlineEdit.setInlineEdits({ edits: {} });
        if (realtimeEvent.data.username !== config?.user?.name || realtimeEvent.data.tabId !== tabId) {
          toast({
            description: `${realtimeEvent.data.username} just published!`,
            variant: 'success'
          });
        }
        break;
      case 'change':
        if (realtimeEvent.data.events?.length) {
          setCanPublish(false);
        }
        console.log("Merging", realtimeEvent.data.uuid, realtimeEvent.data.events.length, realtimeEvent.data.userActivity);
        // Copied from editor.tsx line ~1987 mergeEvents(), there we broke out if we didn't have state as well.
        // Do we want to do that here too?
        mergeEvents(realtimeEvent.data.events, realtimeEvent.data.userActivity);
        break;
      case 'partial_change':
        if (realtimeEvent.data.compressedChunk?.length) {
          setCanPublish(false);
        }
        const currentlyCollected = eventsAwaitingMerge.current[realtimeEvent.data.uuid] || [];
        const decompressedEvents = decompress(realtimeEvent.data.compressedChunk) as ChangeSet;
        eventsAwaitingMerge.current[realtimeEvent.data.uuid] = [...currentlyCollected, decompressedEvents];

        // When all chunks have come in, we can go ahead and merge.
        if (eventsAwaitingMerge.current[realtimeEvent.data.uuid]?.length === realtimeEvent.data.totalChunks) {
          const flattenedEvents = eventsAwaitingMerge.current[realtimeEvent.data.uuid].reduce((acc, value) => acc.concat(value), []);
          console.log("Merging chunked changes", realtimeEvent.data.uuid, flattenedEvents.length, realtimeEvent.data.userActivity);
          mergeEvents(flattenedEvents, realtimeEvent.data.userActivity);
          eventsAwaitingMerge.current[realtimeEvent.data.uuid] = [];
        }

        context.inlineEdit.setRefreshInlineEditSignal((prev) => prev + 1);
        break;
      default: {
        // Ignore unexpected events
        return;
      }
    }

    if (incomingEditsTimer.current) clearTimeout(incomingEditsTimer.current);
    incomingEditsTimer.current = setTimeout(() => {
      setCanPublish(true);
    }, 3000);
  });

  const doSendEvents = async (myActivity?: UserActivity) => {
    const toSend = removeDuplicateEvents(eventsToSend.current || []);
    const uuid1 = uuidv4();
    console.log('Sending events', uuid1, toSend);

    // 100 events has a payload length of roughly 30,000. We have a 32,768 length (32 KB) limit on our production websocket API.
    // If we have more than 100 events to send, let's split and compress them.
    if (toSend.length > 100) {
      // we can compress 700 events into ~29,000, which is small enough to send.
      const chunkSize = 700;
      const numChunks = Math.ceil(toSend.length / chunkSize);
      for (let i = 0; i < toSend.length; i += chunkSize) {
        const chunk = toSend.slice(i, i + chunkSize);
        const compressedChunk = compress(chunk);
        sendEvent({
          realm: 'distro',
          channel,
          event: 'partial_change',
          data: {
            compressedChunk: compressedChunk,
            totalChunks: numChunks,
            userActivity: myActivity,
            uuid: uuid1
          }
        });

        // need this at the moment in order for the listening websocket to correctly
        // receive eveything. Not sure if this is just a dev thing...
        await new Promise(resolve => setTimeout(resolve, 500));
      }
    } else {
      sendEvent({
        realm: 'distro',
        channel,
        event: 'change',
        data: {
          events: toSend,
          userActivity: myActivity,
          uuid: uuid1
        }
      });
    }
    eventsToSend.current = undefined;
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!collabEnabled) {
        return;
      }

      // if we are just coming back, reload to make sure we didn't miss anything.
      if (!document.hidden) {
        // reloadSurvey(true);
        // TODO(Riley): the stateRef thing relies pretty heavily (in Config.tsx) on the handleUpdatesInner() function
        // which responds to changes made in Distro. Watching for changes in the context.inlineEdits might be the most analogous
        // thing for us to do here.
        doSendEvents(stateRef.current?.myActivity);

        // if we are leaving, remove user presence from the document.
      } else if (userContext.uid) {
        doSendEvents({
          uid: userContext.uid,
          name: config?.user?.name,
          tabId,
          lastEditedObject: ''
        })
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    const handleActivity = () => {
      lastActivityTime.current = Date.now();
    };

    const activityEvents = ['mousemove', 'mousedown', 'keydown', 'touchstart'];
    activityEvents.forEach(event => {
      document.addEventListener(event, handleActivity);
    });

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      activityEvents.forEach(event => {
        document.removeEventListener(event, handleActivity);
      });
    };
  }, [collabEnabled]);

  /****** END: Sending Collab Events via WebSockets ******/

  function convertInlineEditsToCollabEvents() {
    let events: ChangeSet = [];
    for (const id of Object.keys(context.inlineEdit.inlineEdits?.edits || {})) {
      for (const lang of Object.keys((context.inlineEdit.inlineEdits?.edits || {})[id] || {})) {
        // Create Collab "patch" operation
        const edit = context.inlineEdit.inlineEdits!.edits[id]![(lang as SupportedLanguage)];
        const op: Patch = {
          op: 'patch',
          id: Math.random().toString(), // TODO: should we be using uuids here? Collab doesn't but that doesn't mean no.
          value: edit.value,
          prev_gen: Math.max(0, edit.prev_gen),
          author
        };

        events.push([id, 'primitive', op]);
      }
    }

    events = removeDuplicateEvents(events);
    return events;
  }

  const hasEdits = (Object.keys(context.inlineEdit?.inlineEdits?.edits || {}).length > 0)

  return <div>
    <div className="p-2 flex flex-col">
      <div className="p-2 flex">
        <h1 className='p-2 pr-5'>{'Inline Editor'}</h1>
        <div>
          {canEdit && (
            <div className='mb-2'>
              <Dropdown
                direction="right"
                label={selectedSubsurveyPathRef.current || 'Select survey'}
                options={subsurveys.map(subSurvey => {
                  return { label: <div>{subSurvey.path}</div>, callback: () => selectSubsurvey(subSurvey) }
                })}
              />
              <ClickableButton
                extraClasses="ml-2"
                color="blue"
                onClick={() => { setInEditMode(!inEditMode); context.inlineEdit.setInlineEditing(!inEditMode); }}
                disabled={!selectedSubsurveyPathRef.current}
              >
                {inEditMode ? 'Stop editing' : 'Start editing'}
              </ClickableButton>
              <ClickableButton
                extraClasses="ml-2"
                color="blue"
                disabled={!inEditMode || !hasEdits}
                onClick={() => { setShowPreview(!showPreview); context.inlineEdit.setShowPreview(!showPreview) }}
              >
                Preview
              </ClickableButton>
              <ClickableButton
                extraClasses="ml-2"
                color="blue"
                disabled={!inEditMode || !hasEdits}
                onClick={() => setShowEdits(!showEdits)}
              >
                Show/Hide Edits
              </ClickableButton>
              <FacePile name={userContext.uid!} channel={get_deployment() + ':entireprogram'} browserTab={tabId} />
            </div>
          )}

        </div>
      </div>
      <div className='flex justify-between mr-auto'>
        {/* The actual wrapped content */}
        {airtableSubsurvey && (
          <div className='px-20'>
            <ModularQuestionPage
              sections={airtableSubsurvey as any}
              info={mockInfo}
              setInfo={(info) => {
                setMockInfo(info);
              }}
              submit={async () => { }}
              saveInfo={async () => { }}
              saveAuth={() => { }}
              noHistory={true}
              sequential={true}
              recompute={0}
            />
          </div>
        )}
        {/* Tailwind text-wrap doesn't play nice with <pre> */}
        {(inEditMode && showEdits && Object.keys(context.inlineEdit.inlineEdits || {}).length > 0) && (
          <pre className='max-w-[500px] border-l-2 pl-2' style={{ textWrap: 'wrap' }}>
            {JSON.stringify(context.inlineEdit.inlineEdits, null, 2)}
          </pre>
        )}
      </div>
    </div>
  </div>
}
