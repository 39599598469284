import { CommonProperties, Collection, Section, CrossProgramDataExchange, Macro, CollectionComponent, SectionComponent, Persona, Dashboard, DashboardComponent, BooleanExpr, GenericTemplatedBlock, SummaryExpr, ValueExpr, Query, Lookup, Block, ApplicantPortalPage } from "@aidkitorg/types/src/survey";
import { Conditional } from "./translation/expr_to_sql";

// may not contain ALL elements,
// but this is purely for crawling 
export type Countable =
  BooleanExpr
  | Dashboard
  | DashboardComponent
  | Persona
  | CollectionComponent
  | SectionComponent
  | Section
  | Collection
  | CrossProgramDataExchange
  | Macro
  | SummaryExpr
  | ValueExpr
  | Conditional
  | Query
  | Lookup['lookup']
  | GenericTemplatedBlock<any>;

export function traverseForKind<T extends { kind: string }>(node: unknown, kind: T['kind']): (T & CommonProperties)[] {
  return traverseForKinds(node, [kind]);
}

export function traverseForKinds<T extends { kind: string }, E extends { kind: string } = any>(node: unknown, kinds: T['kind'][], ignore?: E['kind'][]): (T & CommonProperties)[] {
  let result: T[] = [];
  crawlSurveyKinds(node,
    item => {
      if (kinds.includes(item.kind!)) {
        result.push(item as T);
      }
    },
    item => !!ignore?.includes(item.kind!)
  );
  return result;
}

export function crawlSurveyKinds(elem: any, visitor: (item: Countable) => void, skip?: (item: Countable) => boolean): void {
  if (typeof elem !== 'object') {
    return;
  }

  if (Array.isArray(elem)) {
    for (const i of elem.filter(e => !skip?.(e))) {
      crawlSurveyKinds(i, visitor, skip);
    }
  }

  for (const v of Object.values(elem)) {
    if (typeof v === 'object' && !skip?.(elem)) {
      if (Array.isArray(v)) {
        for (const i of v.filter(e => !skip?.(e))) {
          if (typeof i === 'object') {
            crawlSurveyKinds(i, visitor, skip);
          }
        }
      } else {
        crawlSurveyKinds(v, visitor, skip);
      }
    }
  };

  if (elem.kind && !skip?.(elem)) {
    visitor(elem);
  }
}

export function transform(elem: Countable, visitor: (item: Countable) => Countable): Countable {

  for (const [k, v] of Object.entries(elem)) {
    if (typeof v === 'object') {
      if (Array.isArray(v)) {
        (elem as any)[k] = v.map(i => transform(i, visitor)).filter(i => i)
      } else {
        (elem as any)[k] = transform(v, visitor);
      }
    }
  }

  if (elem.kind) {
    return visitor(elem);
  } else {
    return elem;
  }
}

/**
 * @param components Block[] | ApplicantPortalPage[] // Can be updated to include other types as needed.
 * @returns set of all target fields that are used in the components
 */
export function extractTargetFieldsFromComponents(components: Block[] | ApplicantPortalPage[]): Set<string> {
  const fields = new Set<string>();

  function traverse(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(traverse);
    } else if (typeof obj === 'object' && obj !== null) {
      if (obj.field) {
        fields.add(obj.field);
      }
      if (obj.targetField) {
        fields.add(obj.targetField);
      }
      Object.values(obj).forEach(value => {
        if (typeof value === 'string') {
          const matches: RegExpMatchArray | null = value.match(/\$[a-zA-Z0-9_]+/g);
          if (matches) {
            matches.forEach(match => fields.add(match.substring(1)));
          }
        } else {
          traverse(value);
        }
      });
    }
  }

  traverse(components);
  return fields;
}
