import type { MapRegionDensity } from '@aidkitorg/types/lib/survey';
import type { FeatureCollection, Geometry } from 'geojson';
import { geoMercator, geoPath } from 'd3-geo';
import * as d3Selection from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from  'd3-axis';
import { useDebouncedCallback } from '../Hooks/Debounce';
import { useContext, useLayoutEffect, useRef } from 'react';
import { DashboardComponentProps } from '../DistroDashboard';
import { useLocalizedStrings } from '../Localization';
import InterfaceContext, { SupportedLanguage } from '../Context';

type MapRegionDensityProps = DashboardComponentProps['section'] & MapRegionDensity & {
  geoJSON: FeatureCollection<Geometry, {
    label: string;
    count: number;
    kind: string;
    center: [number, number];
  }>
};

type ChartRenderer = {
  render: (container: HTMLElement, props: MapRegionDensityProps, noResultsHTML: string, lang: SupportedLanguage) => void;
};

const renderer : ChartRenderer = {
  render(container, props, noResultsHTML, lang){
    if(!props.geoJSON?.features?.length){
      container.innerHTML = noResultsHTML;
      return;
    }

    container.innerHTML = '';
    const svg = d3Selection.select(container).append('svg');

    // Set up the color scale, optionally adding pivot/middle if specified in Distro 
    const [minVal, maxVal] = d3Array.extent(props.geoJSON.features.map(f => f.properties.count)) as [number, number];
    const colorDomain = minVal === maxVal ? [0, 1] : [minVal, maxVal]; // Avoids case where all values are equal.
    const colorRange = [props.legend?.gradientStart || '#fff', props.legend?.gradientEnd || '#1e3a8a']; // Defaults to white -> tailwind blue-900
    if (props.legend?.gradientMiddle) {
      colorDomain.splice(1, 0, (maxVal + minVal) / 2);
      colorRange.splice(1, 0, props.legend.gradientMiddle);
    }
    const colorScale = d3Scale.scaleLinear(colorDomain, colorRange).clamp(true);
        
    const padding = 20;
    const { offsetWidth: chartWidth, offsetHeight: chartHeight } = container;

    const projection = geoMercator().fitSize([chartWidth-padding*2, chartHeight-padding*2], props.geoJSON);
    projection.translate(
      projection.translate().map(v => v + padding) as [number, number]
    );
    const pathGenerator = geoPath().projection(projection);
    const supportsPopover = 'popover' in container;
        
    svg.attr('width', chartWidth);
    svg.attr('height', chartHeight);
    svg.attr('viewBox', [0, 0, chartWidth, chartHeight]);

    const popover = d3Selection.select(container).append('div')
      .attr('popover', 'manual')
      .attr('class', 'p-2 m-0 z-10 fixed overflow-visible text-gray-600 text-xs rounded-lg border bg-gray-100 border-gray-800 shadow-sm after:visible after:absolute after:h-2 after:w-2 after:rotate-45 after:bg-inherit after:left-2/4 after:top-full after:-ml-1 after:-mt-1 after:content-[""]')
      .style('pointer-events', 'none');
    const popoverContent = popover.append('div').attr('class', 'popover-content');

    if (supportsPopover) {
      // @ts-ignore
      popover.node()!.hidePopover();
    }

    // SVG elements that will be referenced by ID
    const defs = svg.append('defs');

    // 
    // Set up legend
    // 
    const linearGradient = defs.append('linearGradient')
      .attr('id', 'color-gradient')
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%");

    const stopsData = minVal === maxVal
      ? [minVal]
      : d3Array.range(minVal, maxVal + 1, (maxVal - minVal) / 10);

    linearGradient.selectAll("stop")
      .data(stopsData)
      .join("stop")
      .attr("offset", (d, i, n) => {
        // Calculate how far along the gradient we are as a percentage
        const offset = 100 * (i / (n.length - 1));
        return `${offset}%`;
      })
      .attr("stop-color", d => colorScale(d));

    const legendWidth = chartWidth / 4;
    const legendHeight = 20;
    const legendXPosition = (chartWidth - legendWidth) / 2;
    const legendYPosition = chartHeight - 100;

    // This path defines a line which the legendText uses to place itself under and exactly
    // centered left/right with the legend
    defs.append('path')
      .attr('id', 'legend')
      .attr('d', `M ${legendXPosition}, ${legendYPosition + legendHeight + 35} H ${legendXPosition + legendWidth}`);

    // The filled, gradient part of the legend
    svg.append("rect")
      .attr('x', legendXPosition)
      .attr('y', legendYPosition)
      .attr('width', legendWidth)
      .attr('height', legendHeight)
      .attr('fill', 'url(#color-gradient)');

    // Scale axis below the rectangle
    const legendScale = d3Scale.scaleLinear()
      .domain([minVal, maxVal])
      .range([0, legendWidth]);

    const legendAxis = d3Axis.axisBottom<number>(legendScale)
      .ticks(5)
      .tickSizeOuter(0);

    const legendText = svg.append("text");
    legendText.append('textPath')
      .attr('href', '#legend')
      .attr('startOffset', '50%')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .text(props.legend?.legendText?.[lang] || 'Participants in area')

    svg.append("g")
      .attr("transform", `translate(${legendXPosition}, ${legendYPosition + legendHeight})`)
      .call(legendAxis);

    //
    // Draw geoJSON boundaries and fill based on count calculated in Map Region Density query
    // 
    const pathsGroup = svg.append('g').attr('class', 'boundaries');
    pathsGroup.selectAll("path")
      .data(props.geoJSON.features)
      .join('path')
      .attr('d', pathGenerator)
      .attr('fill', d => d.properties.count > 0 ? colorScale(d.properties.count) : props.legend?.gradientStart || '#fff')
      .attr('stroke-width', 0.5)
      .attr('stroke-linejoin', 'round')
      .attr('stroke', '#333')
      .style('cursor', 'pointer')
      .on('mouseenter', function onMouseEnter(e, datum){
        const { label, count, center } = datum.properties;
        popoverContent.html(`
                    <div class="flex flex-col gap-1 items-start">
                        <div>${props.hoverLabel?.[lang] || 'Name'}: <span class="font-bold">${label}</span></div>
                        <div>Count: <span class="font-bold">${count}<span></div>
                    </div>`);
        const regionCenter = projection(center);
        popover.node()!.classList.remove('hidden');
        popover.node()!.classList.add('visible');    
                
        if (supportsPopover) {
          // @ts-ignore
          popover.node()!.showPopover();
        } 
        if (regionCenter && popover.node()) {
          const [centerX, centerY] = regionCenter;
          const { top, left } = container.getBoundingClientRect();           
          popover.style('left', `${left + centerX - popover.node()!.offsetWidth/2}px`);
          popover.style('top', `${top + centerY - popover.node()!.offsetHeight - 10}px`);
        }                        
      })
      .on('mouseleave', function onMouseLeave(e, datum){
        popover.node()!.classList.remove('visible');
        popover.node()!.classList.add('hidden');
        if (supportsPopover) {
          // @ts-ignore
          popover.node()!.hidePopover();
        }            
      });
  }
}

export function MapRegionDensity(props: MapRegionDensityProps){
  const { width, height } = props;
  const context = useContext(InterfaceContext);
  const containerRef = useRef(null);
  const L = useLocalizedStrings();
  const noResultsHTML = `<p>${L.dashboard.no_results}</p>`;
  const onWindowResize = useDebouncedCallback(() => {
    if (containerRef.current) {
      renderer.render(containerRef.current, props, noResultsHTML, context.lang);
    }
  });
  useLayoutEffect(
    () => {
      if (containerRef.current) {
        renderer.render(containerRef.current, props, noResultsHTML, context.lang);
      }
      window.addEventListener('resize', onWindowResize);
      return () => {
        window.removeEventListener('resize', onWindowResize);
      }
    },
    [containerRef.current, props, context.lang]
  );

  return <div ref={containerRef} className="map-container w-full relative grow" style={{minWidth: `${width}px`, minHeight: `${height}px`}}>
    {/* render graph with D3 */}
  </div>
}