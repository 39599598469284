import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import React, { useMemo, useState } from 'react'

export type BasicComboboxProps<T> = {
  initialSelection?: T,
  items: T[],
  children?: string | JSX.Element,
  onSelectionChanged(item: T): void,
  displayValue?(item: T): string,
  renderOption?(item: T): React.ReactNode,
  filterItem(item: T, query: string): boolean
};
export default function BasicCombobox<T>({
  initialSelection,
  items,
  children,
  onSelectionChanged,
  renderOption,
  displayValue,
  filterItem
}: BasicComboboxProps<T>) {
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState<T | undefined>(initialSelection);

  const filteredItems = useMemo(() =>
    query === ''
      ? items
      : items.filter((item) => filterItem(item, query))
  , [query, items]);

  return (
    <Combobox
      as="div"
      value={selected}
      onChange={(item) => {
        setQuery('');
        setSelected(item);
        onSelectionChanged(item);
      }}
    >
      <Combobox.Label className="block text-sm/6 font-medium text-gray-900">{children}</Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={displayValue ?? ((item: T) => JSON.stringify(item))}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredItems.map((item, i) => (
              <Combobox.Option
                key={i}
                value={item}
                className="group relative hover:bg-blue-100 hover:cursor-pointer cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
              >
                {renderOption ? renderOption(item) :
                  <div className="flex">
                    <span className="truncate group-data-[selected]:font-semibold">{JSON.stringify(item)}</span>
                  </div>
                }
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
