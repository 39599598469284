import React, { useContext, useEffect, useState, useCallback } from 'react';
import InterfaceContext from './Context';
import { ThreeColumnPage } from './Components/ThreeColumnPage';
import { NavigationNode } from '@aidkitorg/types/lib/translation/permissions';
import { usePost } from './API';
import { useCookies } from 'react-cookie';
import { saveKeyToCookieAndRelocateToUrl, SpacedSpinner } from './Util';
import { LoginApplyLink, NewApplicationLink, PortalExplanation, SubsurveyLink, TemplatedApplicationBlock } from '@aidkitorg/types/lib/survey';
import { GetApplicantCandidatesOutput, PotentialCandidate } from 'aidkit/lib/application/multiplex';
import { evalDistroConditional } from '@aidkitorg/types/lib/eval';
import { useLocalizedStrings } from './Localization';
import { useModularMarkdown } from './Hooks/ModularMarkdown';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button } from '@aidkitorg/component-library';
import { useHistory, useParams } from 'react-router-dom';

export function PortalExplanationComponent(props: { explanation: PortalExplanation, info: Record<string, string | undefined > | undefined }) {
  const { content, alignment, accordion } = props.explanation;
  const L = useLocalizedStrings();

  const marked = useModularMarkdown({
    content,
    info: { ...props.info },
    replacer: (str) => str.replace(' **\n','**\n').replace('^** ','**')
  });

  const header = useModularMarkdown({
    content: accordion?.header || '',
    info: { ...props.info },
    replacer: (str) => str.replace(' **\n','**\n').replace('^** ','**'),
  });

  if (accordion) {
    return (
      <Accordion type={"single"} className="w-full text-sm py-2 px-4 mb-4">
        <AccordionItem value={'top item'} >
          <AccordionTrigger>
            <div className="text-sm w-full [&_p]:mb-0 [&_h4]:mb-0 [&_h3]:mb-0 [&_h2]:mb-0 [&_h1]:mb-0" style={{textAlign: accordion.headerAlignment || 'left'}}>
              {header}
            </div>
          </AccordionTrigger>
          <AccordionContent>
            {marked}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    )
  }
  return (
    <div style={{ textAlign: alignment || 'left' } as React.CSSProperties} >
      {marked}
    </div>
  );
}

export function PortalSubsurveyLink(props: {subsurveyLink: SubsurveyLink, candidate: PotentialCandidate, Viewer: 'screener' | 'applicant' }) {
  const resume = usePost("/subsurvey/resume", { token: () => props.candidate.resumeToken });
  const history = useHistory();

  const doResume = async (params: { 
    resumptionToken: string,
  }) => {
    if (props.candidate.applicant.kind === 'ingested') {
      const newURL = `${window.location.protocol}//${window.location.host}/p/apply/?key=${params.resumptionToken}`;
      saveKeyToCookieAndRelocateToUrl(newURL);
      return;
    }

    let resumeToken = props.candidate.resumeToken;
    const previous = await resume({
      form_name: 'apply'
    });

    if (previous?.newToken) {
      resumeToken = previous?.newToken;
    }

    // create a local id, save the token and info to the session, and redirect to the apply page
    // with the local id so it knows where the data is.
    const id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    window.sessionStorage['auth:' + id] = resumeToken;
    window.sessionStorage['cache:' + id] = JSON.stringify(previous?.info);
    history.push(`/apply/${id}`);
  }

  const link = props.candidate.subsurveys?.find(subsurvey => {
    // get the path to verify applicant can access the link
    const path = new URL(subsurvey.link).pathname;
    const result = path.split('/').pop();
    return result === props.subsurveyLink.subsurvey;
  })

  const marked = useModularMarkdown({
    content: props.subsurveyLink.label,
    info: { ...props.candidate.info },
    replacer: (str) => str.replace(' **\n','**\n').replace('^** ','**'),
  });

  // Don't show these if the applicant doesn't have access to the link
  if (!(link || (props.subsurveyLink.subsurvey === 'apply' && props.candidate.resumeToken))) {
    return null;
  }

  return <div className="w-full" style={{textAlign: props.subsurveyLink.alignment || 'left'}}>
    <Button
      disabled={props.Viewer === 'screener'}
      className="mb-4 pr-5 pl-5 [&_p]:mb-0 [&_h4]:mb-0 [&_h3]:mb-0 [&_h2]:mb-0 [&_h1]:mb-0"
      onClick={ props.subsurveyLink.subsurvey === 'apply'
        ? (e) => doResume({ resumptionToken: props.candidate.resumeToken })
        : e => window.location.href = link?.link || window.location.href }
      variant={props.subsurveyLink.variant || 'secondary'}
    >
      {marked}
    </Button>
  </div>;
}

export function PortalNewApplicationLink(props: { newApplicationLink: NewApplicationLink, canStartNewToken: string, Viewer: 'screener' | 'applicant' }) {
  const history = useHistory();

  const marked = useModularMarkdown({
    content: props.newApplicationLink.label,
    info: {},
    replacer: (str) => str.replace(' **\n','**\n').replace('^** ','**'),
  });

  const resume = usePost("/subsurvey/resume", { token: () => props.canStartNewToken });
  const doResume = async (params: {
    resumptionToken: string,
  }) => {
    let confirmToken = props.canStartNewToken;
    const previous = await resume({
      form_name: 'apply'
    });
    if (previous?.newToken) {
      confirmToken = previous?.newToken;
    }
    // create a local id, save the token and info to the session, and redirect to the apply page
    // with the local id so it knows where the data is.
    // TODO: add ref = 'portal' to info so we know this app came from.
    // Double check that this info is actually saved to the session.
    const id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    window.sessionStorage['auth:' + id] = confirmToken;
    window.sessionStorage['cache:' + id] = JSON.stringify(previous?.info || {});
    history.push('/apply/?ref=portal&localid=' + id);
  }

  return <div className="w-full" style={{textAlign: props.newApplicationLink.alignment || 'left'}}>
    <Button 
      disabled={props.Viewer === 'screener'}
      className="mb-4 pr-5 pl-5 [&_p]:mb-0 [&_h4]:mb-0 [&_h3]:mb-0 [&_h2]:mb-0 [&_h1]:mb-0"
      onClick={ (e) => doResume({ resumptionToken: props.canStartNewToken + ':new' }) }
      variant={props.newApplicationLink.variant || 'secondary'}
    >
      {marked}
    </Button>
  </div>;
}

export function PortalLoginApplyLink(props: { newApplicationLink: LoginApplyLink, Viewer: 'screener' | 'applicant' }) {
  const history = useHistory();

  const marked = useModularMarkdown({
    content: props.newApplicationLink.label,
    info: {},
    replacer: (str) => str.replace(' **\n','**\n').replace('^** ','**'),
  });

  const link = props.newApplicationLink.subsurvey === 'apply' ? '/apply/' : `/p/${props.newApplicationLink.subsurvey}/`;

  return <div className="w-full" style={{textAlign: props.newApplicationLink.alignment || 'left'}}>
    <Button 
      disabled={props.Viewer === 'screener'}
      className="mb-4 pr-5 pl-5 [&_p]:mb-0 [&_h4]:mb-0 [&_h3]:mb-0 [&_h2]:mb-0 [&_h1]:mb-0"
      onClick={ (e) => history.push(link)}
      variant={props.newApplicationLink.variant || 'secondary'}
    >
      {marked}
    </Button>
  </div>;
}

function ApplicantTemplateComponent(props: { candidates: GetApplicantCandidatesOutput | null, applicantTemplate: TemplatedApplicationBlock, Viewer: 'screener' | 'applicant' }) {
  const appsToInclude = props.candidates ? props.candidates.candidates.filter((candidate) => {
    if (candidate.info) {
      return evalDistroConditional(props.applicantTemplate.appsToInclude, candidate.info);
    } else {
      return false;
    }
  }) : [];

  const header = useModularMarkdown({
    content: props.applicantTemplate.header,
    info: {count: appsToInclude.length.toString()},
    replacer: (str) => str.replace(' **\n','**\n').replace('^** ','**'),
  });

  const renderComponent = useCallback((component: any, index: number, app: PotentialCandidate) => {
    if (component.conditionalOn) {
      if (!evalDistroConditional(component.conditionalOn, app.info || {})) return null;
    }

    switch (component.kind) {
      case 'Portal Explanation':
        return <PortalExplanationComponent explanation={component} info={app.info} key={index} />
      case 'Subsurvey Link':
        return <PortalSubsurveyLink subsurveyLink={component} candidate={app} key={index} Viewer={props.Viewer} />
      default:
        console.log('Unsupported component type:', component);
        return <div key={index}>Unsupported component type: {component.kind} </div>;
    }
  }, [props.candidates]);

  if (appsToInclude.length === 0) {
    if (props.applicantTemplate.hideIfEmpty || props.applicantTemplate.ifEmpty === 'hide') return null;
    if (props.applicantTemplate.ifEmpty?.kind === 'Templated Application Block') return <ApplicantTemplateComponent candidates={props.candidates} applicantTemplate={props.applicantTemplate.ifEmpty} Viewer={props.Viewer}/>;
  }
  
  if (props.applicantTemplate.accordion) return (
    <Accordion type={"single"} className="w-full text-sm bg-slate-50 py-2 px-4 mb-4">
      <AccordionItem value={'top item'} className="border-none">
        <AccordionTrigger>
          <div className="text-sm w-full [&_p]:mb-0 [&_h4]:mb-0 [&_h3]:mb-0 [&_h2]:mb-0 [&_h1]:mb-0" style={{textAlign: props.applicantTemplate.headerAlignment || 'left'}}>
            {header}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="divide-y divide-gray-200 b-none">
            {appsToInclude.map(app => {
              const appID = app.applicant.kind === 'ingested' ? app.applicant.uid : app.applicant.dynamo_uid
              return (
                <div key={appID} className="pb-3 pt-3">
                  {props.applicantTemplate.components.map((component, index) => renderComponent(component, index, app))}
                </div>
              );
            })}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
  
  return (
    <div className="w-full text-sm bg-slate-50 py-2 px-4 mb-4">
      <div style={{textAlign: props.applicantTemplate.headerAlignment || 'left'}}>
        {header}
      </div>
      {appsToInclude.map(app => {
        const appID = app.applicant.kind === 'ingested' ? app.applicant.uid : app.applicant.dynamo_uid
        return (
          <div key={appID}>
            {props.applicantTemplate.components.map((component, index) => renderComponent(component, index, app))}
          </div>
        );
      })}
    </div>
  );
}

export function ApplicantPortalPage(props: { Viewer: 'screener' | 'applicant' }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [nav, setNav] = useState<NavigationNode[]>([]);
  const [components, setComponents] = useState<any[]>([]);
  const [isComponentsLoading, setIsComponentsLoading] = useState<boolean>(true);
  const [candidates, setCandidates] = useState<GetApplicantCandidatesOutput | null>(null);
  const [isCandidatesLoading, setIsCandidatesLoading] = useState<boolean>(true);
  const { uid } = useParams() as Record<string, string>;


  const context = useContext(InterfaceContext);
  const [cookies, setCookie] = useCookies(['portal', 'auth_token']);

  const getApplicantCandidates = usePost("/applicant/get_candidates", { token: () => props.Viewer === 'applicant' ? cookies['portal'] : cookies['auth_token'] });
  const getScreenerApplicantCandidates = usePost("/applicant/get_candidates");
  const getPortalComponents = usePost("/applicant/get_portal", { token: () => props.Viewer === 'applicant' ? cookies['portal'] : cookies['auth_token'] });
  const history = useHistory();
  const L = useLocalizedStrings();

  const path = window.location.pathname.split('/').filter(Boolean).pop();

  // Have to pass document cookie directly to usePost because it doesn't update when the cookie changes
  // This is a hacky workaround and will only update on rerenders. But react doesn't track cookies.
  const getNav = usePost("/applicant/navigation", { token: () => document.cookie.split("; ").find((c) => c.startsWith("portal="))?.split("=")[1] || '' });
  const getScreenerNav = usePost("/navigation");
  const getEverything = usePost('/applicant/get_everything');


  useEffect(() => {
    (async () => {
      const response = (
        props.Viewer === 'screener' ? await getScreenerNav({ lang: context.lang, applicant: uid })
          : await getNav({ lang: context.lang })
      )
      setNav(response.navigation);
    })();
  }, [document.cookie, getNav, context.lang]);

  useEffect(() => {
    if (props.Viewer === 'applicant') {
      setIsLoggedIn(!!document.cookie.split("; ").find((c) => c.startsWith("portal="))?.split("=")[1]);
      return;
    }
    setIsLoggedIn(true);
  }, [document.cookie]);

  useEffect(() => {

    const doGetApplicantCandidates = async () => {
      if (!isLoggedIn) {
        setIsCandidatesLoading(false);
        return;
      }
      setIsCandidatesLoading(true);

      if (props.Viewer === 'screener') {
        const info = (await getEverything({ uid: uid }))?.info || {};
        const candidatesResults = await getScreenerApplicantCandidates({ info , mode: 'portal' })
        setCandidates(candidatesResults);
      } else {
        const candidatesResults = await getApplicantCandidates({ info: {}, mode: 'portal' })
        if (candidatesResults.error && candidatesResults.error === 'token_expired') {
          // Clear all site cookies by overwriting them with past expiry dates
          // This ensures we log out of any /p/ subsurveys we may have accessed during the session.
          document.cookie.split(';').forEach(cookie => {
            // dont delete auth_token for admins doing testing
            if (cookie.trim().startsWith('auth_token')) return;
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/';
          });
          sessionStorage.clear();
          localStorage.clear();
          alert(L.apply.youve_been_logged_out)

          history.push('/o/home');
        }
        setCandidates(candidatesResults);
      }
      setIsCandidatesLoading(false);
    };

    doGetApplicantCandidates()
  }, [isLoggedIn, getApplicantCandidates, getScreenerApplicantCandidates, uid, L, history, props.Viewer]);

  useEffect(() => {
    // Load dynamic components for the portal
    const loadComponents = async () => {
      if (!path) return;
      setIsComponentsLoading(true);
      try {
        const response = await getPortalComponents({ portalPage:  path });
        if (response.error) {
          throw new Error(response.error);
        } else {
          setComponents(response.portalPage?.components || []);
        };
      } catch (error) {
        console.error('Error loading portal components:', error);
      } finally {
        setIsComponentsLoading(false);
      }
    };
    loadComponents();
  }, [isLoggedIn, getPortalComponents, context.lang, path]);

  const renderComponent = useCallback((component: any, index: number) => {
    switch ( component.showWhen ) {
      case 'Always':
        break;
      case 'LoggedIn':
        if (!isLoggedIn) return null;
        break;
      case 'LoggedOut':
        if (isLoggedIn) return null;
        break;
      default:
        break;
    };

    switch (component.kind) {
      case 'Templated Application Block':
        return <ApplicantTemplateComponent key={index} candidates={candidates} applicantTemplate={component} Viewer={props.Viewer} />;
      case 'Portal Explanation':
        return <PortalExplanationComponent key={index} explanation={component} info={{}} />
      case 'New Application Link':
        return candidates?.canStartNewToken && isLoggedIn ? 
          <PortalNewApplicationLink key={index} newApplicationLink={component} canStartNewToken={candidates.canStartNewToken} Viewer={props.Viewer} />
          : null;
      case 'Login Apply Link':
        return <PortalLoginApplyLink key={index} newApplicationLink={component} Viewer={props.Viewer} />
      default:
        console.log('Unsupported component type:', component);
        return <div key={index}>Unsupported component type: {component.kind} </div>;
    }
  }, [isLoggedIn, candidates]);
  const main = (
    <div className="max-w-4xl mx-auto applicant-led p-2 pb-36">
      {isComponentsLoading || isCandidatesLoading ? (
        <div className="flex justify-center items-center h-screen">
          <SpacedSpinner className="h-20 w-20 text-gray-400" /> {/* TODO: replace with Page Loading Component once we have one */}
        </div>
      ) : (
        components.map((component, index) => renderComponent(component, index))
      )}
    </div>
  );
  return <ThreeColumnPage main={main} Viewer={props.Viewer} nav={nav} />;
}
