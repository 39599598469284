import React, { useEffect } from "react";

/**
 * Use with async functions, which may or may not contain cancellable aspects.
 *
 * using the signal is optional, but recommended! 
 * It notifies the async function when cleanup is occuring.
 *
 * e.g. 
 * ```
 *  useAsyncEffect(async (signal) => {
 *    const resp = await fetch('https://mywebsite.org/todo/items', { signal })
 *  }, [items]);
 *```
 *
 * with our usePost() callback hook!
 * ```
 *   const getEverything = usePost('/applicant/get_everything')
 *
 *   useAsyncEffect(async (signal) => {
 *     const resp = await getEverything({ uid }, undefined, signal);
 *     setApplicant(resp);
 *   }, [getEverything, items]);
 * ```
 */
export function useAsyncEffect(fn: (abort: AbortSignal) => Promise<void>, deps?: React.DependencyList) {
  useEffect(() => {
    const abort = new AbortController();
    fn(abort.signal).catch(() => {});
    return () => { try { abort.abort(); } catch { /* this is expected, so no action needed */ } }
  }, deps);
}
