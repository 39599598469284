import { Button, ResizablePanel, Tabs, useToast } from "@aidkitorg/component-library";
import { PublicConfig } from '@aidkitorg/types/lib/config';
import { Collection, Text } from '@aidkitorg/types/lib/survey';
import { collectNavigation, NavigationNode } from '@aidkitorg/types/lib/translation/permissions';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChevronDownIcon,
  CogIcon,
  CurrencyDollarIcon,
  FolderIcon,
  FolderOpenIcon,
  GlobeAltIcon,
  IdentificationIcon,
  PlusCircleIcon,
  PresentationChartLineIcon,
  QuestionMarkCircleIcon,
  RectangleStackIcon,
  UserCircleIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import { WifiIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { usePost } from '../API';
import InterfaceContext, { ConfigurationContext, PublicConfigurationContext, UserInfoContext } from '../Context';
import { useLocalizedStrings } from "../Localization";
import { getOrCreateHttpCache } from '../offline';
import { SyncStatus, toApplicant } from '../offline/routes';
import { AidKitLogo, snakeToEnglish, SpacedSpinner, useInterval } from '../Util';
import { SearchBar } from './ApplicantSearch';
import { ClearVirtualFileSystemButton } from './ClearVirtualFileSystemButton';
import FlyoutMenu from './FlyoutMenu';
import { ForceWorkerUpdateButton } from './ForceWorkerUpdateButton';
import { LanguageDropdown } from "./LanguageDropdown";
import OfflineChangeCounter from './OfflineChangeCounter';
import { useUserAllowed } from './PermissionsUtil';
import { ZipFileSystem } from './ZipFileSystem';
import Log from "@twilio/voice-sdk/es5/twilio/log";
import { useCookies } from "react-cookie";

export type SubTab = {
  key: string,
  name: string,
  children: JSX.Element,
  show: boolean
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const StandardMenu = [
  { name: 'Explore', href: '/explore', icon: QuestionMarkCircleIcon, current: false },
  { name: 'Config', href: '#', icon: CogIcon, current: false },
];

export default function ExampleThreeColumnPage() {
  const navigation: NavigationNode[] = [
    { name: 'Queues', href: '#', icon: RectangleStackIcon,
      subnodes: [{
        name: 'Needs Review', href: '#', icon: RectangleStackIcon,
      }] },
    { name: 'Applicant', href: '#', icon: UserIcon },
    { name: 'Dashboards', href: '#', icon: ChartBarSquareIcon },
    { name: 'Explore', href: '#', icon: QuestionMarkCircleIcon },
    { name: 'Config', href: '#', icon: CogIcon, },
  ];

  return <ThreeColumnPage
    nav={navigation}
    main={<></>}
    subtabs={[
      { key: 'Notes', name: 'Notes', show: true, children: <>Wee</> },
    ]}
  />
}

function getLangText(lang?: keyof Text, value?: string | Text): string | null | undefined {
  if(typeof value === 'string' || value === null || typeof value === 'undefined') {
    return value;
  }
  return value[lang ?? 'en'] ?? value['en'];
}

function Item(props: {item: NavigationNode, depth?: number}): JSX.Element {
  const [open, setOpen] = useState(true)
  const item = props.item;
  let depth = props.depth;
  depth = depth || 0;
  const history = useHistory();
  const context = useContext(InterfaceContext);
  const name = getLangText(context.lang, item.name);

  // If there are subnodes and not a subsurvey, render them
  const sub = !name?.startsWith('/p/') && item.subnodes && item.subnodes.length ?
    <div className={'space-y-1 pt-2 ' +(depth === 0 ? '' : '')}>
      {item.subnodes?.map((i) => <Item item={i} depth={props.depth! + 1} key={i.href + Math.random()}/>)}
    </div> : <></>;

  if (item.href === '' && name == '') {
    return <>{sub}</>
  }

  if (item.icon === 'Section' || item.icon === 'Subsurvey') {
    // /a/ and /ua/ are subsurveys, /ad/ is applicant specific dashboards (eg: Applicant Identity) /ao/ is Applicant Portal views.
    if (!['/a/', '/ua/', '/ad/', '/ao/'].some(prefix => window.location.pathname.startsWith(prefix))) {
      return <></>;
    }
  }

  if (item.isFolder && !item.subnodes?.length) {
    return <></>;
  }

  const active = item.href.split('#')[1] === decodeURIComponent(window.location.hash.slice(1)) || window.location.pathname === item.href;

  return <div
    className={classNames(
      active
        ? 'border-indigo-600 text-gray-900'
        : 'border-gray-50 text-gray-600 hover:bg-gray-50 hover:text-gray-900',
      'bg-white border-solid border-l-4 border-r-0 border-y-0 group items-center px-2 text-sm font-medium'
    )}>
    <div
      className={classNames(
        active
          ? 'border-indigo-600 text-gray-900'
          : 'border-gray-200 text-gray-600 hover:text-gray-900',
        'flex border-solid py-2 px-1 -mx-3 border-l-4 border-r-0 border-y-0 group items-center text-sm font-medium'
      )}>
      {item.isFolder ?
        (open ? <FolderOpenIcon className="text-gray-600" width={20} /> : <FolderIcon className="text-gray-600" width={20} />)
        : (item.icon && TypeToIcon[item.icon] &&
                    React.createElement(TypeToIcon[item.icon], { className: "text-gray-600", width: 20}))}
      <a
        className='ml-1 text-gray-600 font-medium cursor-pointer'
        key={name}
        {...item.href ? { href: item.href} : {}}
        href={item.href}
        onClick={(e) => {
          props.item.isFolder && setOpen(!open);
          if (!props.item.isFolder) {
            history.push(item.href);
            e.preventDefault();
          } else {
            e.preventDefault();
          }
        }}
      >
        { name?.startsWith('/p/') ? snakeToEnglish(name.slice(3)) : name }
      </a>
    </div>
    {(open || !item.isFolder) && sub}
  </div>;
}

export const TypeToIcon: Record<string, any> = {
  Collection: FolderIcon,
  Dashboard: PresentationChartLineIcon,
  Notification: WifiIcon,
  Payment: CurrencyDollarIcon,
  Section: IdentificationIcon,
  Subsurvey: ChartPieIcon,
  Queue: RectangleStackIcon,
  SupportDashboard: ChatBubbleOvalLeftEllipsisIcon
}

export function CollectionTree(props: { collection: Collection }) {
  return <Item item={collectNavigation(props.collection)} key={props.collection.name.en} />
}

export function CommandPalette(props: { open: boolean, setOpen: (open: boolean) => void }) {
  const history = useHistory();
  const [query, setQuery] = useState('')
  const queryRef = useRef('');
  const [queryTimer, setQueryTimer] = useState<any | null>(null)
  const [results, setResults] = useState<Awaited<ReturnType<typeof search>>['data']>([]);
  const filteredPeople =
    query === ''
      ? []
      : [];

  const search = usePost("/search");

  async function doSearch() {
    let results: Awaited<ReturnType<typeof search>>;
    const query = queryRef.current;
    if (query.match(/^[0-9]+$/)) {
      results = await search({ field: 'phone_number', term: query })
    } else if (query.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      results = await search({ field: 'email', term: query })
    } else {
      results = await search({ field: 'legal_name', term: query })
    }
    setResults(results.data);
  }

  return (
    <Transition.Root show={props.open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-50" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={(person: any) => {
                history.push(`/a/${person}`);
                props.setOpen(false);
              }}>
                <Combobox.Input
                  className="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  onChange={(event) => {
                    if (queryTimer) {
                      clearTimeout(queryTimer);
                    }
                    setQuery(event.target.value)
                    queryRef.current = event.target.value;
                    setQueryTimer(setTimeout(doSearch, 500));
                  }}
                />

                {results.length > 0 && (
                  <Combobox.Options
                    static
                    className="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {results.map(((person) => (
                      <Combobox.Option
                        key={person.applicant}
                        value={person.applicant}
                        className={({ active }) =>
                          classNames(
                            'cursor-default select-none rounded-md px-4 py-2',
                            active ? 'bg-indigo-600 text-white' : ''
                          )
                        }
                      >
                        {person.name}
                      </Combobox.Option>
                    )))}
                  </Combobox.Options>
                )}

                {query !== '' && filteredPeople.length === 0 && (
                  <div className="py-14 px-4 text-center sm:px-14">
                    <p className="mt-4 text-sm text-gray-900">No people found using that search term.</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export function AddApplicantButton() {
  const config: PublicConfig = useContext(PublicConfigurationContext);
  const addNew = usePost('/applicant/create');
  const L = useLocalizedStrings();
  const user = useContext(UserInfoContext);
  const { toast } = useToast();
  // hides button if addApplicant capability is present, but not filled in.
  // shows button if the capability is absent
  const addApplicantAllowed = !config?.capabilities?.addApplicant || (user?.tags ?? []).some(
    (r: string) => config?.capabilities?.addApplicant?.includes(r)
  );

  async function createApplicant() {
    if(!addApplicantAllowed) {
      toast({
        description: "Nice, you figured out how to unhide this button",
        variant: 'error',
        duration: 1000,
      });
      return;
    }
    const legal_name = await prompt('What is the name of the applicant?');
    if (!legal_name) return;

    const result = await addNew({ legal_name });
    if (result.success) {
      window.location.href = `/a/${result.newId}/apply`;
    }
  }

  return <div
    className={'border-gray-200 text-gray-600 hover:text-indigo-600'}>
    <a
      className='flex ml-1 pl-2 text-gray-600 hover:text-indigo-600 font-medium cursor-pointer'
      key={'addApplicant'}
      onClick={createApplicant} hidden={!addApplicantAllowed} >
      <PlusCircleIcon className="text-gray-600 hover:text-indigo-600 -mt-1 mr-1" width={20} />
      {L.dashboard.add_new_applicant}
    </a>
  </div>
}

export function LogoutButton() {
  const L = useLocalizedStrings();

  function logoutCheck() {
    const wordCheck = prompt(
      L.applicant.comms.i_want_to_log_out_check.replace(
        '$n',
        L.applicant.comms.i_want_to_log_out
      ),
      ''
    );
    if(wordCheck?.toLowerCase() === L.applicant.comms.i_want_to_log_out.toLowerCase()) {
      window.location.assign('/logout');
    }
  }

  return <a onClick={logoutCheck} className="text-blue-500 cursor-pointer">
    {L.menu.logout}
  </a>;
}

const ApplicantLogoutButton = () => {
  const history = useHistory();

  const logout = () => {
    // Clear all site cookies by overwriting them with past expiry dates
    // This ensures we log out of any /p/ subsurveys we may have accessed during the session.
    document.cookie.split(';').forEach(cookie => {
      // dont delete auth_token for admins doing testing
      if (cookie.trim().startsWith('auth_token')) return;
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/';
    });

    sessionStorage.clear();

    localStorage.clear();

    history.push('/o/home');
  };

  const isLoggedIn = document.cookie.split("; ").find((c) => c.startsWith("portal="))?.split("=")[1]

  return isLoggedIn ? <div className="pb-3 text-center">
    <Button onClick={logout} variant="danger">
      Log out
    </Button>
  </div> : null;
};

export function ThreeColumnPage(props: { nav?: NavigationNode[], title?: string, main: JSX.Element, banner?: JSX.Element, subtabs?: SubTab[], Viewer?: 'applicant' | 'screener'}) {
  const configuration = useContext(ConfigurationContext);
  const interfaceContext = useContext(InterfaceContext);
  const publicConfig = useContext(PublicConfigurationContext);
  const [nav, setNav] = useState<NavigationNode[]>([]);
  const [commandPaletteOpen, setCommandPaletteOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string | undefined>(props.subtabs?.find(tab => tab.show === true)?.key);
  const [tabs, setTabs] = useState<SubTab[] | null>(props.subtabs?.filter((tab) => tab.show) ?? null);
  const getNav = usePost("/navigation");
  const [showGlobalScope, setShowGlobalScope] = useState(props.nav ? false : true);
  const L = useLocalizedStrings();
  const containerRef = useRef<HTMLDivElement>(null);

  // Feature Guards
  const searchAllowed = useUserAllowed(publicConfig.capabilities?.applicantSearch?.length ? publicConfig.capabilities.applicantSearch : ['lookup','admin']);

  // Fetch the navigation data
  useEffect(() => {
    if (props.Viewer === 'applicant') {
      return;
    }
    (async () => {
      const toUnroll = (await getNav({ lang: interfaceContext.lang })) as { navigation: NavigationNode[] };
      setNav(toUnroll.navigation);
    })()
  }, [interfaceContext.lang]);

  const filteredTabs = useMemo(() => {
    return props.subtabs?.filter((tab) => tab.show) ?? [];
  }, [props.subtabs]);

  useEffect(() => {
    setTabs(filteredTabs);
  }, [filteredTabs]);

  // Subtab definitions can change as configs are successfully fetched asynchronously.
  // If we don't yet have a default selectedTab when subtabs change, find 
  // the first valid tab we can show and set that to be the active tab.
  useEffect(() => {
    if (!selectedTab && props.subtabs) {
      const defaultTab = props.subtabs.find(tab => tab.show === true)?.key;

      if (defaultTab) {
        setSelectedTab(defaultTab)
      }
    }
  }, [props.subtabs])

  const readyForNextPublish = useRef(true);
  const postStats = usePost('/publish_stats', { keepAlive: true });

  async function publishStats() {
    const applicantDir = await getOrCreateHttpCache('applicants');
    const applicants = await applicantDir.dirs();
    const appStates = await Promise.all(applicants.map(async a => {
      const app = await a.as(toApplicant);
      return await app.state();
    }));
    const changeCounts = appStates.map(a => a.status === SyncStatus.Ahead ? (a as any).changes : 0);
    const drafts = appStates.filter(a => a.status === SyncStatus.BrandNew);
    const workerRegistrations = await navigator?.serviceWorker.getRegistrations();
    const workerState = workerRegistrations.map(r =>
      r.active ? 'active'
        : r.installing ? 'installing'
          : r.waiting ? 'waiting'
            : 'stalled'
    ).join('|');

    const workerVersion = await fetch('/service-worker/version', { method: 'POST' });

    await postStats({
      deviceName: localStorage.getItem("device_name")!,
      statsType: 'normal',
      stats: {
        downloadedApplicantCount: applicants.length,
        applicantsWithChanges: changeCounts.filter(c => c > 0).length,
        changeCountTotal: changeCounts.reduce((a, b) => a + b, 0),
        workerState: workerState.length > 0 ? workerState : 'unregistered',
        applicantDraftCount: drafts.length,
        workers: workerRegistrations.length,
        workerVersion: workerVersion.ok ? await workerVersion.text() : 'N/A'
      }
    });
  };

  useInterval(() => {
    if(!publicConfig.experimental?.enableOfflineMode || props.Viewer === 'applicant') {
      return;
    }
    if(readyForNextPublish.current) {
      (async () => {
        readyForNextPublish.current = false;
        try {
          await publishStats();
        } catch(err) {
          console.error('unable to publish stats, will try again shortly', err);
        } finally {
          readyForNextPublish.current = true;
        }
      })();
    }
  }, 10 * 1000);

  const applicantFacingLogo = publicConfig.interface?.applicantFacingLogo?.url || configuration.applicant_facing_logo;
  const applicantFacingLogoWidth = publicConfig.interface?.applicantFacingLogo?.width || configuration.applicant_facing_logo_width || '150';

  const programName = publicConfig.name || configuration.program_name || 'AidKit Program';

  const navigationPanel = (
    <div className="h-full flex min-h-0 flex-1 flex-col">
      <div className="relative py-4 px-4">
        <LanguageDropdown languages={(configuration.languages || 'en,es').split(',')} />
      </div>
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex flex-shrink-0 items-center px-4 py-2">
          {applicantFacingLogo ? (
            <img
              src={applicantFacingLogo}
              width={applicantFacingLogoWidth}
              alt={programName}
            />
          ) : (
            <AidKitLogo width={100} height={50} />
          )}
        </div>
        {props.Viewer !==  'applicant' && <div className='px-4 py-2'>
          <h3>{!showGlobalScope ?
            <><UserCircleIcon className='inline-block mb-2' width={30} /> <div className='inline-block mt-2'>{props.title || 'Applicant'}</div></> :
            <><GlobeAltIcon className='inline-block mb-2' width={30} /> <div className='inline-block mt-2'>{programName}</div></>
          }</h3>
        </div>}
        {/* Hiding this until we set up proper permissioning for it */}
        {/* <button className="border-none bg-white ml-3 text-gray-600 font-medium text-left" onClick={() => setCommandPaletteOpen(true)}>
                  <SearchIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                  Search
                  </button> */}
        <nav className="mt-2 ml-2 flex-1" aria-label="Sidebar">
          {props.nav && props.Viewer !== 'applicant' && (!showGlobalScope ?
            <a
              className='flex ml-1 text-gray-600 font-medium cursor-pointer'
              onClick={(e) => { setShowGlobalScope(true); e.preventDefault(); }}
            >
              <ArrowLeftIcon className="text-gray-600" width={20} /> {L.menu.back_to_dashboards}
            </a> :
            <a
              className='flex ml-1 text-gray-600 font-medium cursor-pointer'
              onClick={(e) => { setShowGlobalScope(false); e.preventDefault(); }}
            >
              <ArrowRightIcon className="text-gray-600" width={20} /> {L.menu.back_to_applicant} {props.title || 'Applicant'}
            </a>
          )}
          <div className="space-y-1 px-2">
            {showGlobalScope && <AddApplicantButton />}
            {showGlobalScope &&
              publicConfig.interface?.includeProtectedSearchInNav &&
              searchAllowed &&
              <SearchBar newInterface />
            }
            {(showGlobalScope ? nav : props.nav || []).filter(v => v).map((i) => <Item item={i} key={i.href + Math.random()} />)}
          </div>
        </nav>
        { props.Viewer !== 'applicant' ? <table className="min-w-full divide-y divide-gray-300">
          <tbody>
            <tr className="flex mb-3 place-content-center space-x-5">
              <td>
              </td>
              <td>
                <ul className="space-y-1 p-2">
                  <FlyoutMenu
                    label={
                      <div className="flex items-center space-x-1">
                        <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
                        <span>{publicConfig.experimental?.enableOfflineMode ? L.offline.offline_menu : L.menu.title}</span>
                      </div>
                    }
                    items={
                      <ul className="p-2 space-y-4 text-blue-600">
                        {
                          // Only exposing account settings if passwords are being used since we
                          // only have Security settings. We can open this up for all once there are more settings.
                          publicConfig.adminPasswords && <>
                            <li>
                              <Link to="/admin/account_settings">{L.admin.account_settings.title}</Link>
                            </li>
                          </>
                        }
                        {publicConfig.experimental?.enableOfflineMode ?
                          <>
                            <li><LogoutButton /></li>
                            <li><ForceWorkerUpdateButton /></li>
                            <li><ClearVirtualFileSystemButton /></li>
                            <li><ZipFileSystem /></li>
                            <li>
                              <a
                                onClick={async () => {
                                  const name = prompt('what would you like to name the device?');
                                  if (name) {
                                    localStorage.setItem("device_name", name);
                                  }
                                }}
                                className="cursor-pointer">
                                Set Device Name
                              </a>
                            </li>
                          </>
                          : <li><LogoutButton /></li>
                        }
                      </ul>
                    }
                  />
                  <div className="pl-0" hidden={!publicConfig.experimental?.enableOfflineMode}>
                    <OfflineChangeCounter />
                  </div>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
          : <ApplicantLogoutButton /> }
      </div>
    </div>
  );

  const applicantInfoPanel = (
    <Tabs
      className="w-full px-1"
      onChange={(key) => setSelectedTab(key)}
      selectedTab={selectedTab}
      tabs={
        tabs?.map((tab, index) => (
          {
            name: tab.name,
            key: tab.key,
            content: (<div className="p-4">{tabs?.[index].children}</div>)
          }
        )) ?? []
      }
    />
  );

  const mainContent = (
    <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
      <div className="absolute inset-0">
        {props.main}
      </div>
    </main>
  );

  return (
    <>
      {interfaceContext.staffBanner &&
        <div className={"sticky text-center top-0 z-50 p-3 border-solid border-gray-200 border-t-0 border-x-0 " +
          interfaceContext.staffBanner.__style} style={{borderBottomWidth: '1px'}}>{interfaceContext.staffBanner[interfaceContext.lang]}</div>}
      <div className="h-screen flex" ref={containerRef}>
        <ResizablePanel side="left" label={L.menu.navigation_panel} dir={interfaceContext.direction} initialWidthPercent={20} as="nav" viewportRef={containerRef}>
          {navigationPanel}
        </ResizablePanel>
        {mainContent}
        {tabs && tabs.length > 0 && (
          <ResizablePanel side="right" label={L.menu.applicant_info_panel} dir={interfaceContext.direction} initialWidthPercent={25} displayDensity="compact" as="aside" viewportRef={containerRef}>
            {applicantInfoPanel}
          </ResizablePanel>
        )}
      </div>
      <CommandPalette open={commandPaletteOpen} setOpen={setCommandPaletteOpen} />
    </>
  )
}
