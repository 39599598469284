import { useEffect, useState, useContext, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useToast } from "@aidkitorg/component-library";
import InterfaceContext, { AuthContext } from "./Context";
import { useCookies } from "react-cookie";
import { useLocalizedStrings } from "./Localization";
import * as Sentry from "@sentry/react";
import type { Directory } from 'aidkit/lib/directory';
import type { Directory as RoboNav } from '@aidkitorg/robonav/lib';
import type { extractParams, extractReturn } from 'aidkit/lib/common/handler';
import { createLogger, DefaultStyles } from './utils';
import { gzipSync, strToU8 } from "fflate";
// import { VERSION } from "./Version";

// const TEST_LIVE_DEPLOYMENT = "dbip";
const TEST_LIVE_DEPLOYMENT = "cookcounty";

const logger = createLogger(
  'API',
  DefaultStyles({
    info: '#b48eff',
    warn: '#fff26d',
    error: '#f8766d',
    debug: '#799997',
    assert: '#a9ffcb'
  }),
  ['debug']
);

// Pulls the token from cookies or search query
// TODO: see if we can just return one thing rather than two
function useToken() {
  const cookieName = encodeURIComponent('key:' + window.location.pathname)
  const [cookies, ,] = useCookies(["auth_token", cookieName]);

  // check for URL param first in case it hasn't been removed and set as a cookie yet.
  if (window.location.search.indexOf("?key=") === 0) {
    return [cookies, "auth=" + window.location.search.slice("?key=".length)];
  }

  if (cookies[cookieName]) {
    return [cookies, "auth=" + cookies[cookieName]];
  }

  return [
    cookies,
    cookies["auth_token"] ? "auth=" + cookies["auth_token"] : "",
  ];
}

export const specialHostnameDeployments = {
  'chicagocashpilot.org': 'chicagorcp',
  'promisepilot.cookcountyil.gov': 'cookcounty',
  'jfssd.aidkit.org': 'jfssdcws',
  'workerscomp.cloud': 'workerscomp'
} as Record<string, string>;

export function get_deployment(): string {
  if (window.location.hostname.indexOf("local") !== -1 || window.location.hostname.includes('ts.net') || window.location.href.includes('host.docker.internal')) {
    return 'postgres';
  }
  if (specialHostnameDeployments[window.location.hostname]) {
    return specialHostnameDeployments[window.location.hostname];
  }
  if (window.location.hostname === "127.0.0.1" || window.location.hostname.indexOf('bzn.network') !== -1) {
    return TEST_LIVE_DEPLOYMENT;
  }
  if (window.location.hostname.indexOf("staging") !== -1) {
    return window.location.hostname.split(".")[0] + ".staging";
  }
  return window.location.hostname.split(".")[0];
}

export function get_rs_host() {
  if (window.location.hostname.includes('ts.net')) {
    return "https://" + window.location.hostname + ':3031/api';
  }
  if (window.location.hostname.indexOf("local") !== -1) {
    return (
      "http://" + window.location.hostname + ":3030/api"
    );
  }
  return "https://roboscreener-api.aidkit.org";
}

export function get_host() {
  if (window.location.hostname.indexOf("local.dev.aidkit.com") !== -1) {
    return "http://dev.dev.aidkit.com:8000"
  }
  if (window.location.hostname.includes('ts.net')) {
    return "https://" + window.location.hostname + ':8001';
  }
  // We're talking to production from dev frontend
  if (window.location.hostname.indexOf("dev.aidkit.com") !== -1) {
    return "https://" + window.location.hostname.split(".")[0] + ".api.aidkit.cloud"
  }
  if (window.location.hostname.indexOf("local") !== -1) {
    const prefix = window.location.hostname === 'localhost' ? 'dev' : window.location.hostname.split('.')[0];
    return (
      "http://" + prefix + ".aidkit.local:8000"
    );
  }
  if (specialHostnameDeployments[window.location.hostname]) {
    return "https://" + specialHostnameDeployments[window.location.hostname] + ".api.aidkit.cloud";
  }
  if (window.location.hostname === "127.0.0.1" || window.location.hostname.indexOf('aidkit') === -1) {
    return "https://" + TEST_LIVE_DEPLOYMENT + ".api.aidkit.cloud";
  }
  if (window.location.hostname.indexOf("staging") !== -1) {
    return (
      "https://" + window.location.hostname.split(".")[0] + ".staging.api.aidkit.cloud"
    );
  }
  return (
    "https://" + window.location.hostname.split(".")[0] + ".api.aidkit.cloud"
  );
}

function concatPathAndQueryParams(path: string, params: any[]): string {
  // replace Token ? with & if path includes ?
  let symbol = "?";
  if (path.includes(symbol)) symbol = "&";
  params.map(p => {
    if (p.indexOf("?") === 0 || p.indexOf("&") === 0) {
      let e = "Param '" + p + "' has a ? or & symbol as the first character.";
      throw e;
    }
    return p;
  });
  return path + symbol + params.join("&");
}

export function handleDataError(data: any, handleToast?: (params: Parameters<ReturnType<typeof useToast>['toast']>[0]) => void, options?: { suppressErrorDisplay?: boolean }): void {
  if (data && data.error) {
    const doNotToast = [
      'Not authorized', 
      'previous_info_does_not_match', 
      'Not authorized for channel', 
      'Route has not been exposed', 
      'incorrect_password', 
      'invalid_token', 
      'expired_token',
      'account_temporary_locked',
    ];
    const errorMessages: Record<string, string> = {
      'incorrect_code': "Incorrect code",
      'old_password_incorrect': "Incorrect old password",
      'new_password_same_as_old_password': "Your new password cannot be the same as your old password",
      'invalid_user': "Invalid user"
    }

    // We already redirect to /login if this is the case, 
    // so we don't need to show a bunch of error msgs as well.
    if (doNotToast.includes(data.error || data.message || '')) {
      return;
    }

    if (!!options?.suppressErrorDisplay) {
      console.error(`Error: ${data.message ?? data.error}`);
      return;
    }

    if (data.message) {
      if (handleToast) {
        handleToast({
          title: "Error",
          description: typeof data.message === 'string' ? data.message : JSON.stringify(data.message),
          variant: "error"
        })
      }
    } else {
      const deploymentName = get_deployment();
      Sentry.setContext('deployment', { 'name': deploymentName });
      Sentry.setTag("deployment_name", deploymentName);
      Sentry.captureException(data.error);
      let error = JSON.stringify(data.error);
      if (handleToast) {
        if (error.includes('Invalid WSDL URL')) {
          handleToast({
            title: "Error",
            description: "Error loading USIO data, please try again later.",
            variant: "error"
          })
        } else {
          const toastMessage: string = typeof data.error === 'string' ? data.error : JSON.stringify(data.error)
          handleToast({
            title: "Error",
            description: errorMessages[toastMessage] ?? toastMessage,
            variant: "error"
          })
        }
      }
    }
  }
}

export function useDownloader() {
  const host = get_host();
  const [, token] = useToken();

  return (path: string, filename?: string) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = (path.startsWith('blob:') || path.startsWith('http'))
      ? path
      : host + concatPathAndQueryParams(path, [token]);
    a.setAttribute('download', filename || '');
    a.click();
    document.body.removeChild(a);
  }
}

const redirectToLogin = (history: any, pathname: string, L: any) => {
  if (!pathname.startsWith("/login")) {
    Sentry.getCurrentScope().setUser(null);
    history.push("/login?next=" + encodeURIComponent(pathname));
  }
}

const allowedDomains = ['aidkit.org','aidkit.cloud','chicagocashpilot.org','workerscomp.cloud','aidkit.loca:', 'cookcountyil.gov'];
export const apiPath = () => {
  for (const domain of allowedDomains) {
    if (window.location.origin.indexOf(domain) !== -1) {
      return "https://aidkit-api.aidkit.org/";
    }
  }
  if (window.location.origin.indexOf("local.dev.aidkit.com") !== -1) {
    return "http://127.0.0.1:4321/";
  }
  if (window.location.origin.indexOf("ts.net") !== -1) {
    return "https://" + window.location.hostname + ":4322/";
  }
  // We're actually talking to production from a dev frontend
  if (window.location.origin.indexOf("dev.aidkit.com") !== -1) {
    return "https://aidkit-api.aidkit.org/";
  }
  if (window.location.origin.indexOf("dev.aidkit") !== -1) {
    return "http://dev.aidkit.localhost:4321/"; // localhost needs no DNS Lookups
  }
  if (window.location.origin.indexOf("host.docker.internal") !== -1) {
    return "http://host.docker.internal:4321/"; // running within a local env docker container
  }
  return "http://mbp-vm.bzn.network:4321/"; // BZN wuz here
}

export const robonavApiPath = () => {
  if (window.location.origin.indexOf("local.dev.aidkit.com") !== -1
        // internal docker host implies this is running in a container,
        // and that container is running the browser.
        || window.location.origin.indexOf('host.docker.internal') !== -1) {
    return "http://127.0.0.1:41892/";
  }
  if (window.location.origin.indexOf("ts.net") !== -1) {
    return "https://" + window.location.hostname + ":41892/";
  }
  // We're actually talking to production from a dev frontend
  if (window.location.origin.indexOf("dev.aidkit.com") !== -1) {
    return "https://robonav-api.aidkit.org/";
  }
  if (window.location.origin.indexOf("dev.aidkit") !== -1) {
    return "http://dev.aidkit.localhost:41892/"; // localhost needs no DNS Lookups
  }
  return "https://robonav-api.aidkit.org/";
}

// const headers = new Headers({ "Content-Type": "application/json", "X-Frontend-Revision": VERSION || ''});
const normalHeaders = new Headers({ "Content-Type": "application/json" });

export const useGet = <P extends keyof Directory>(path: P, includeRefresh?: boolean): extractReturn<Directory[P]> => {
  return useAPI('api:/' + path + '?program=' + get_deployment(), includeRefresh);
};

const useAPI = (path: string, includeRefresh?: boolean): any => {
  const isTS = path.indexOf("api://") === 0;

  path = path.replace("api://", apiPath())

  const [data, setData] = useState({});
  const history = useHistory();
  const location = useLocation();
  const context = useContext(InterfaceContext);
  let [cookies, token] = useToken();
  const path_ = path; // This silences some warnings because I reassign below
  const L = useLocalizedStrings();
  const { toast } = useToast();

  const setActive = useCallback(context.setRequestActive, []);
  
  const doRequest = useCallback(
    (pathOverride?: string) => {
      (async () => {
        const reqId = Math.random().toString(36);
        try {
          let path: string = path_;
          if (pathOverride) {
            path = pathOverride;
          }
          setActive(reqId, true);
          const host = get_host();

          if (isTS && !path.includes('token=')) { token = (token as any).replace('auth=', 'token='); }
          let url = host + concatPathAndQueryParams(path, [token]);
          if (path.indexOf("http") === 0) {
            if (isTS) {
              url = concatPathAndQueryParams(path, [token]);
            } else {
              url = path;
            }
          }

          url = concatPathAndQueryParams(url, ["lang=" + context.lang]);

          const result = await fetch(url);

          if (result.status === 200 && (result as any)?.error !== 'Route has not been exposed') {
            let intermediate_data = await result.json();
            handleDataError(intermediate_data, (payload: Parameters<ReturnType<typeof useToast>['toast']>[0]) => toast(payload));
            setData(intermediate_data);
          }
          if (result.status === 401 || (result as any)?.error === 'Route has not been exposed') {
            redirectToLogin(history, location.pathname + location.hash, L);
          }
          if (result.status === 404 && result.body) {
            const resultText = await result.text();
            const siteDoesNotExist = resultText && resultText.includes('site_does_not_exist');
            if (siteDoesNotExist && !location.pathname.startsWith("/site_not_found")) {
              const deploymentName = get_deployment();
              Sentry.setContext('deployment', { 'name': deploymentName });
              Sentry.setTag("deployment_name", deploymentName);
              history.push("/site_not_found");
            } else if (resultText && resultText.indexOf('Applicant does not exist') !== -1) {
              toast({
                description: L.applicant_not_found,
                variant: 'error'
              })
              //history.push("/");
            }
          }
          
        } catch (e: any) {
          // moves cases of failed network requests to the console.
          // otherwise, these will pop up incessantly on the
          // top-right of the screen
          if (e.message === "Failed to fetch") {
            logger.error("Error", e, path);
          } else {
            const errorMessage: string = e.message && typeof e.message === 'string' ? e.message : undefined;
            const toastMessage: string = errorMessage || JSON.stringify(e);
            toast({
              title: "Error",
              description: toastMessage,
              variant: 'error'
            })
          }
        } finally {
          setActive(reqId, false);
        }
      })();
    },
    [token, history, location.pathname, path_, setActive, context.lang, L]
  );

  useEffect(() => {
    doRequest();
  }, [cookies, doRequest]);

  if (includeRefresh) {
    return [data, doRequest];
  }

  return data;
};

const doRawPost = async (path: string, params: any) => {
  const pairs = document.cookie.split(';').map((e) => e.trim().split('=')).filter((e) => e[0] === 'auth_token')
  let token = '';
  if (pairs.length) {
    token = '?auth=' + pairs[0][1];
  }

  const host = get_host();
  let url = host + path + token;
  if (path.indexOf("http") === 0) {
    url = path;
  }
  const result = await fetch(url, {
    method: "POST",
    headers: normalHeaders,
    body: JSON.stringify(params),
    mode: "cors",
  });
  return await result.json();
}

export const usePost = <P extends keyof (Directory & RoboNav)>(path: P, options?: Parameters<typeof useAPIPost>[1]): 
(params: extractParams<(Directory & RoboNav)[P]>, pathOverride?: string, signal?: AbortSignal) => Promise<extractReturn<(Directory & RoboNav)[P]>> => {
  return useAPIPost('api:/' + path + '?program=' + get_deployment(), options)
};

export const useRoboNav = <P extends keyof RoboNav>(path: P, options?: Parameters<typeof useAPIPost>[1]): 
(params: extractParams<RoboNav[P]>, pathOverride?: string, signal?: AbortSignal) => Promise<extractReturn<RoboNav[P]>> => {
  return usePost(path, { endpoint: robonavApiPath(), handleErrors() {/* we return the error field in test results, so we silence this */}, ...options });
};

const useAPIPost = (path: string, options?: { 
  compressRequestPayload?: boolean,
  customHeaders?: Record<string, string>
  includeDeploymentKeyInData?: boolean,
  includeTokenInData?: boolean,
  token?: () => string,
  textOnly?: boolean,
  keepAlive?: boolean,
  asBlob?: boolean,
  // Since toasts are managed in the component lifecycle and since some of the utility functions
  // in here exist outside of the scope of the React lifecycle, we need to let the components themselves
  // handle triggering toasts.
  handleErrors?: (e: Error, toastHandler?: (payload: Parameters<ReturnType<typeof useToast>['toast']>[0]) => void) => void,
  suppressErrorDisplay?: boolean,
  endpoint?: string
}) => {

  path = path.replace("api://", options?.endpoint ?? apiPath());

  const authContext = useContext(AuthContext);
  const context = useContext(InterfaceContext);
  const location = useLocation();
  const history = useHistory();
  const [, token] = useToken();
  const L = useLocalizedStrings();
  const {toast} = useToast();

  const setRequestActive = context.setRequestActive;
  const post = useCallback(async (data: any, pathOverride?: string, signal?: AbortSignal) => {
    data['lang'] = data['lang'] ?? context.lang;
    logger.debug("posting", data, path);
    const reqId = Math.random().toString(36);
    try {
      let realPath = path;
      if (pathOverride) {
        realPath = pathOverride;
      }
      setRequestActive(reqId, true);
      const host = get_host();
      const resolvedToken = options?.token?.() ?? authContext?.token?.() ?? token;
      let url = host + concatPathAndQueryParams(realPath, [resolvedToken]);
      if (realPath.indexOf("http") === 0) {
        url = concatPathAndQueryParams(realPath, [(options?.token ? 'token=' + options?.token?.() : (
          authContext?.token?.() ? 'token=' + authContext?.token?.() : (token ? 'token=' + (token as string).replace('auth=', '') : '')
        ))]);
      }
      
      // Include token in data so we don't have to do URL parsing
      if (options?.includeTokenInData) {
        data['token'] = resolvedToken;
      }

      if (options?.includeDeploymentKeyInData) {
        data['deploymentKey'] = get_deployment();
      }
      
      try {
        data['userAgent'] = navigator.userAgent;      
      } catch (e) {
        data['userAgent'] = 'error';
      }

      // We may pass the session token in headers via simplewebauthn, for now just lay the groundwork
      // For the ability to add custom headers.
      let headers: Headers | Record<string, string> = (options && options.customHeaders) ? options.customHeaders : normalHeaders;
      
      // Uint8Array is used for gzip-compressed payloads
      let payloadBody: string | Uint8Array;

      if (options && options.compressRequestPayload) {
        payloadBody = gzipSync(strToU8(JSON.stringify(data)));

        if (headers instanceof Headers) {
          // Note: We can't simply append this header to the `headers` variable because headers points to normalHeaders and
          // that can persist between API calls within the same execution context. To ensure isolation, modify headers only
          // for this specific `useAPIPost` call and point `headers` to this new Headers object.
          const gzipHeaders = new Headers(headers);
          gzipHeaders.append("Content-Encoding", "gzip");
          
          headers = gzipHeaders;
        } else {
          headers = {
            "Content-Encoding": "gzip",
            ...headers,
          }
        }
      } else {
        payloadBody = JSON.stringify(data)
      }
      
      const result = await fetch(url, {
        method: "POST",
        headers,
        body: payloadBody,
        mode: "cors",
        keepalive: options?.keepAlive,
        signal
      });
      if (result.status === 200) {
        if (options?.textOnly) {
          return await result.text();
        }

        if (options?.asBlob) {
          return await result.blob();
        }

        const data = await result.json();

        if (data?.error === 'Route has not been exposed') {
          redirectToLogin(history, location.pathname + location.hash, L);
        }

        if (data?.__banner) {
          context.setBanner(data.__banner);
        }
        
        options?.handleErrors
          ? options.handleErrors(data, (payload: Parameters<ReturnType<typeof useToast>['toast']>[0]) => toast(payload))
          : handleDataError(data, (payload: Parameters<ReturnType<typeof useToast>['toast']>[0]) => toast(payload), { suppressErrorDisplay: !!options?.suppressErrorDisplay })

        return data;
      }
      if (result.status === 401) {
        redirectToLogin(history, location.pathname + location.hash, L);
        try {
          return await result.json();
        } catch (e) {
          return { error: "Not authorized" };
        }
      }
    } catch (e) {
      // AbortErrors are from us canceling the request. We don't need to log these.
      if ((e as any).name !== 'AbortError') {
        logger.error("Error", e, path);
        throw e;
      }
    } finally {
      setRequestActive(reqId, false);
    }
  }, [path, setRequestActive, history, location.pathname, token, context.lang, L]);
  return post;
};

const useAPIUpload = (path: string, progressCallback?: (progress: number) => any) => {
  path = path.replace("api://", apiPath())

  const context = useContext(InterfaceContext);
  const location = useLocation();
  const history = useHistory();
  const [, token] = useToken();
  const L = useLocalizedStrings();

  async function post(files: any[], params?: any, actualPath?: string, method?: "POST" | "PUT", localProgressCallback?: (progress: number) => any) {
    if (actualPath) path = actualPath;
    logger.debug("uploading", path, files);
    const reqId = Math.random().toString(36);
    try {
      context.setRequestActive(reqId, true);
      const host = get_host();
      let url = host + concatPathAndQueryParams(path, [token]);
      if (path.indexOf("http") === 0) {
        url = path;
      }
      const formData = new FormData();
      logger.debug(files);
      for (const file of files) {
        formData.append("file", file);
      }
      if (params) {
        for (const key in params) {
          formData.append(key, params[key]);
        }
      }

      /*
      const result = await fetch(url, {
        method: "POST",
        body: formData,
        mode: "cors",
      });
      if (result.status === 200) {
        return await result.json();
      }
      */

      const xhr = new XMLHttpRequest();
      const success = await new Promise((resolve, reject) => {
        xhr.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            logger.debug("upload progress:", event.loaded / event.total);
            progressCallback && progressCallback(event.loaded / event.total);
            localProgressCallback && localProgressCallback(event.loaded / event.total);
          }
        });
        xhr.addEventListener("loadend", () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            if (path.indexOf('http') === -1) {
              resolve(JSON.parse(xhr.responseText));
            }
            resolve(xhr.responseText);
          } else if (xhr.readyState === 4 && xhr.status === 204) {
            resolve(xhr.responseText);
          } else if (xhr.readyState === 4) {
            reject(new Error(xhr.statusText));
          }
        });
        xhr.open(method || "POST", url, true);
        //xhr.setRequestHeader("Content-Type", "application/octet-stream");
        if (method === "PUT") {
          xhr.send(files[0]);
        } else {
          xhr.send(formData);
        }
      });
      return success as any;

      /*
      if (result.status === 401) {
        redirectToLogin(history, location.pathname, L);;
      }
      */
    } catch (e) {
      logger.error("Error ", e, path);
      throw e;
    } finally {
      context.setRequestActive(reqId, false);
    }
  }
  return post;
};

export { useAPI, useAPIPost, useAPIUpload, useToken, doRawPost };
